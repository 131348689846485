import React from "react";
import Dropdown from "components/Dropdown";
import {useTypedSelector} from "helpers/reducers";
import {Notification, NotificationType} from "types/entities/Notification";
import {GeneralRacerAvatar} from "pages/Front/components/GeneralRacerAvatar";
import {BellAlertIcon, BellIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import {driverName} from "utils/utility";
import {Driver} from "types/entities/Driver";
import SessionAction from "store/session/SessionAction";
import { useDispatch } from "useDispatch";
import { CheckIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

interface NotificationItemProps {
    notification: Notification;
    index: number;
    onRead: (notification: Notification) => void
}

const NotificationItem = (props: NotificationItemProps) => {
    const {t} = useTranslation();
    const message = props.notification.data.message || "";

    const transposedMessage = message => {
        switch (message) {
            case "ping":
                return t("Has pinged you!");
            default:
                return message;
        }
    };
    return (
        <div className={clsx(props.notification.read_at !== null ? "max-h-0 py-0 overflow-hidden": "max-h-[320px] p-3 sm:p-3", "NotificationItem duration-500 ease-in-out transform-gpu z-10 flex flex-row  odd:bg-gray-50 border-b border-gray-50 justify-between items-center bg-white")}
             data-index={props.index}
        >
            {props.notification.type === NotificationType.UserNotification && (
                <div className="relative mr-2">
                    <div className="flex items-center justify-center">
                        <GeneralRacerAvatar className={"w-10 h-10 sm:w-12 sm:h-12"} driver={props.notification.driver as Driver} />
                    </div>
                </div>
            )}
            <div className="w-auto">
                <p className="font-medium font-sans-serif-g text-xs leading-3 flex flex-col">
                    <span className={"font-bold mb-1"}>
                        {props.notification.type === NotificationType.UserNotification &&
                            props.notification.driver &&
                            driverName(props.notification.driver as Driver) + " :"}
                    </span>
                    {transposedMessage(message)}
                </p>
            </div>
            <button className={clsx(props.notification.read_at !== null ? "text-gray-800" : "text-gray-700", "w-6 h-6 shrink-0 hover:text-gray-800")}
                    type={"button"} 
                    onClick={() => props.notification.read_at === null && props.onRead(props.notification)}>
                <CheckIcon className={"w-full h-full object-cover"} />
            </button>
        </div>
    );
};

const NotificationCount = (props: {count: number}) => {
    return (
        <div className="absolute select-none pointer-events-none text-white z-50 flex top-4 right-1 -translate-y-1/2">
            <div className="flex flex-col font-bold text-sm items-center justify-center w-4 h-4 bg-red-500 p-1 rounded-full">{props.count}</div>
        </div>
    );
};

const NotificationsDropdown = () => {
    const notifications = useTypedSelector(state => state.sessionState.notifications);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const onMarkAllReadHandler = () => {
        dispatch(SessionAction.markAllNotificationsRead()).then(r => {
        })
    };
    const unreadNotificationsCount = notifications.filter(notification => notification.read_at === null);
    const onMarkNotificationReadHandler = (notification: Notification) => {
        dispatch(SessionAction.markNotificationRead(notification)).then(r => {

        })
    }
    return (
        <Dropdown
            nextToTitle={notifications && notifications.length > 0 ? <NotificationCount count={unreadNotificationsCount.length} /> : <></>}
            title={notifications && notifications.length > 0 ? <BellAlertIcon className={"w-6 h-6"} /> : <BellIcon className={"w-6 h-6"} />}
        >
            <div className={"NotificationsScroller flex flex-col overflow-y-auto max-h-[50vh]"}>
            {notifications &&
                notifications.length > 0 &&
                notifications.map((notification: Notification, index) => {
                    return <NotificationItem key={index} index={index} notification={notification} onRead={onMarkNotificationReadHandler} />;
                })}
            </div>
            {notifications && notifications.length > 0 && <button className={"underline underline-offset-2 w-full px-4 py-4 my-2 mx-2"} type={"button"} onClick={onMarkAllReadHandler}>{t("Mark all read")}</button>}
            {notifications &&
            notifications.length === 0 && (
              <div className={"NotificationsEmpty  z-10 flex flex-row px-4 py-4 border-b border-gray-50 justify-between items-center bg-white"}>{t("No notifications")}</div>
            )}
        </Dropdown>
    );
};

export default NotificationsDropdown;
