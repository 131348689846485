import {combineReducers} from "redux";
import * as Actions from "./actionTypes";
import {IS_DEV} from "helpers/config";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import update from "immutability-helper";
import {ReservationDriver, ReservationSlot} from "types/app";
import ArticlesReducer from "store/articles/ArticlesReducer";
import ShopReducer from "store/shop/ShopReducer";
import BookingReducer from "store/booking/BookingReducer";
import ReservationReducer from "store/reservations/ReservationReducer";
import SessionReducer from "store/session/SessionReducer";
import HallReducer from "store/hall/HallReducer";
import VoucherReducer from "store/shop/VoucherReducer";
import ReferralReducer from "store/referrals/ReferralReducer";
import FriendReducer from "store/referrals/FriendReducer";
import PurchaseHistoryReducer from "store/purchaseHistory/PurchaseHistoryReducer";

const plainSet = (state, defaultState, action, actionType, setTo) => {
    if (typeof state === "undefined") {
        return defaultState;
    }

    if (action.type === actionType) {
        return setTo;
    } else {
        return state;
    }
};

function updateOrInsertState(state, findKey, findValue, addArray, updateArray) {
    const index = state.findIndex(item => item[findKey] === findValue);
    if (index === -1) {
        return update(state, {
            $push: addArray,
        });
    }

    return update(state, {
        [index]: updateArray,
    });
}

interface ArticleInShop {
    article_id?: number;
    quantity: number;
}

export interface ShopState {
    articleItems: ArticleInShop[];
    adult: 0;
    child: 0;
}

const shopDefaultState: ShopState = {
    articleItems: [],
    adult: 0,
    child: 0,
};

type ShopAction = {
    type: string; // action: type
    article_id?: number;
    increment: number;
    person_type?: "adult" | "child";
};

function shop(state = shopDefaultState, action: ShopAction) {
    if (typeof state === "undefined") {
        return shopDefaultState;
    }

    switch (action.type) {
        case Actions.MANIPULATE_SHOP_ITEM:

            if (!action.article_id) {
                throw new Error("Article ID is required");
            }
            // here we got an article

            const ticketIndex = state.articleItems.findIndex(ticket => ticket.article_id === action.article_id);
            if (ticketIndex === -1) {
                if (action.increment < 0) {
                    return state;
                }

                return update(state, {
                    articleItems: {
                        $push: [
                            {
                                article_id: action.article_id,
                                quantity: action.increment,
                            },
                        ],
                    },
                });
            }

            return update(state, {
                articleItems: {
                    [ticketIndex]: {
                        quantity: {
                            $apply: x => x + action.increment,
                        },
                    },
                },
            });
        default:
            return state;
    }
}

const articles = (state = null, action) => plainSet(state, null, action, Actions.SET_ARTICLES, action.payload);
const queues = (state = null, action) => plainSet(state, null, action, Actions.SET_QUEUES, action.payload);

const defaultMessageState = {
    messages: [],
};

const message = (state = null, action) => plainSet(state, defaultMessageState, action, Actions.SET_MESSAGE, action);
const blur = (state = false, action) => plainSet(state, false, action, Actions.SET_BLUR, action.blur);
const reservations = (state = null, action) => plainSet(state, null, action, Actions.SET_RESERVATIONS, action.reservations);

function result(state = null, action) {
    return plainSet(state, null, action, Actions.SET_RESULT, action.result);
}

function ajaxLoading(state = 0, action) {
    if (typeof state === "undefined") {
        return 0;
    }

    switch (action.type) {
        case Actions.AJAX_LOADING:
            console.log("Ajax Loading +" + action.loading + " on URL " + action.url);
            return +state + action.loading;
        default:
            return state;
    }
}

function ajaxError(state = "", action) {
    if (typeof state === "undefined") {
        return "";
    }
    switch (action.type) {
        case Actions.SET_ERROR:
            return action.error;
        default:
            return state;
    }
}

function debug(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    if (action.type === Actions.SET_DEBUG && IS_DEV) {
        return action.data;
    } else {
        return state;
    }
}

function modal(state: null | any, action: any) {
    if (typeof state === "undefined") {
        return {
            component: null,
        };
    }
    if (action.type === Actions.OPEN_MODAL) {
        if (action.toggle) {
            if (state && state.component && state.component === action.component) {
                return {
                    component: null,
                    props: {
                        ...state.props,
                        ...(action.props || {}),
                    },
                    isOpen: !state.isOpen,
                };
            }
        }
        const newState = action.component
            ? {
                  isOpen: true,
                  ...action,
              }
            : {
                  component: null,
                  props: {},
              };

        return newState;
    } else {
        return state;
    }
}
function payment(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_PAYMENT:
            return action.payment;
        default:
            return state;
    }
}

export interface ReservationState {
    drivers: ReservationDriver[];
    slots: ReservationSlot[];
    //result: ReservationResult;
    result: any;
}


const reservationDefaultState: ReservationState = {
    drivers: [],
    slots: [],
    result: null,
};

function reservation(state: ReservationState = reservationDefaultState, action) {
    if (typeof state === "undefined") {
        return null;
    }
    switch (action.type) {
        case Actions.CREATE_RESERVATION:
            return reservationDefaultState;
        case Actions.DELETE_RESERVATION_DRIVER: {
            const driver = action.payload.driver;
            const driverIndex = state.drivers.findIndex(item => item.identifier === driver.identifier);
            return update(state, {
                drivers: {
                    $splice: [[driverIndex, 1]],
                },
            });
        }

        case Actions.SET_RESERVATION_SLOTS: {
            return update(state, {
                slots: {
                    $set: action.payload.slots,
                },
            });
        }

        case Actions.CREATE_RESERVATION_DRIVER: {
            const newDriver = action.payload.driver;
            if (action.payload.oldDriver) {
                const oldDriver = action.payload.oldDriver;
                const oldDriverIndex = state.drivers.findIndex(driver => driver.identifier === oldDriver.identifier);
                return update(state, {
                    drivers: {
                        [oldDriverIndex]: {
                            $set: newDriver,
                        },
                    },
                });
            } else {
                return update(state, {
                    drivers: {
                        $push: [newDriver],
                    },
                });
            }
        }
        case Actions.SELECT_RESERVATION_SLOT: {
            const slot = action.payload.slot;
            const slotIndex = state.slots.findIndex(item => item.identifier === slot.identifier);
            const selected = state.slots[slotIndex].drivers.length > 0;
            const newDrivers = selected ? [] : state.drivers.filter(driver => driver.races_count > driver.races_assigned_count);
            const newState = update(state, {
                slots: {
                    [slotIndex]: {
                        drivers: {
                            $set: newDrivers,
                        },
                    },
                },
            });
            const sumDrivers = {};
            newState.slots.map(slot => {
                slot.drivers.map(driver => {
                    if (!sumDrivers[driver.identifier]) {
                        sumDrivers[driver.identifier] = 0;
                    }
                    sumDrivers[driver.identifier]++;
                });
            });

            const updatedDrivers = newState.drivers.map(driver => {
                driver.races_assigned_count = sumDrivers[driver.identifier] || 0;
                return driver;
            });

            return update(newState, {
                drivers: {
                    $set: updatedDrivers,
                },
            });
        }
        case Actions.SET_RESERVATION_RESULT: {
            return update(state, {
                result: {
                    $set: action.payload.result,
                },
            });
        }
        case Actions.SET_RESERVATION_DRIVER_RACES: {
            const driver = action.payload.driver;
            const driverIndex = state.drivers.findIndex(item => item.identifier === driver.identifier);
            //const newRaceCount = driver[driverIndex].race_count + action.payload.increment;

            const newReservationState = update(state, {
                drivers: {
                    [driverIndex]: {
                        races_count: {
                            $apply: x => {
                                return action.payload.increment + x;
                            },
                        },
                        selected: {
                            $apply: x => {
                                return state.drivers[driverIndex].races_count + action.payload.increment > 0;
                            },
                        },
                    },
                },
            });
            return newReservationState;
        }
        default:
            return state;
    }
}
const reducers = combineReducers(
    /*<State>*/ {
        message,
        reservations,
        blur,
        ajaxLoading,
        payment,
        modal,
        debug,
        reservation,
        articles,
        shop,
        queues,
        hallState: new HallReducer().reducer,
        sessionState: new SessionReducer().reducer,
        articlesState: new ArticlesReducer().reducer,
        shopState: new ShopReducer().reducer,
        bookingState: new BookingReducer().reducer,
        voucherState: new VoucherReducer().reducer,
        friendsState: new FriendReducer().reducer,
        reservationState: new ReservationReducer().reducer,
        referralsState: new ReferralReducer().reducer,
        purchaseHistoryState: new PurchaseHistoryReducer().reducer,
    }
);

export type RootState = ReturnType<typeof reducers>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default reducers;
