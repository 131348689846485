import React, {useEffect, useState} from "react";
import Dates from "utils/Dates";
import T from "components/T";
import {useTypedSelector} from "helpers/reducers";
import {Profile} from "types/entities/Profile";
import Loader from "components/Loader";


interface DashboardHeaderProps {
    title: string | Element | JSX.Element;
    titleSuffix: string | Element | JSX.Element;
    subtitle: string | Element | JSX.Element;
    rightPanelContent?: any;
    profile?: Profile;
}

const DashboardHeader = (props: DashboardHeaderProps) => {
    const {title, titleSuffix, subtitle, rightPanelContent} = props;
    const [time, setTime] = useState<string | null>(Dates.time());
    const profile = props.profile || useTypedSelector(state => state.sessionState.profile);

    if (!profile) {
        return null;
    }


    const profileHall = profile.hall;
    const hall = useTypedSelector(state => state.hallState.hall);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(Dates.time());
        }, 5000);
        return () => {
            clearTimeout(timer);
        }
    }, []);
    let status;

    if (!hall) {
        return <Loader/>;
    }

    if (profileHall && profileHall.hallOpeningHours) {
        status = (
            <span>
            </span>
        );
    } else {
        status = (
            <span>
                {hall.name}&nbsp;
                <T>is closed</T>
            </span>
        );
    }

    return (
        <div className="DashboardHeader flex flex-col sm:flex-row justify-between items-center sm:items-start lg:mb-2 sm:mb-0">
            <div className="inline-flex flex-col">
                <h2 className="font-normal text-xl lg:text-xl xl:text-2xl 2xl:text-3xl leading-5 font-m lg:spacing-px">
                    {title} <span className="font-light">{titleSuffix}</span>
                </h2>
                <div className="pl-1 font-i hidden lg:flex leading-4 lg:leading-4 xl:leading-4 text-sm xl:text-xl 2xl:text-2xl opacity-50">{subtitle}</div>
            </div>
            {!rightPanelContent && (
                <div className="flex-col hidden sm:flex items-end">
                    <h3 className="font-bold text-2xl lg:text-2xl xl:text-4xl  leading-3  uppercase font-m spacing-px">{time}</h3>
                    <div className="font-i text-sm xl:text-xl leading-3 opacity-50">{status}</div>
                </div>
            )}
            {rightPanelContent && <div className="mt-4 sm:mt-0 flex-col flex items-center justify-center space-y-2">{rightPanelContent}</div>}
        </div>
    );
};
export default DashboardHeader;
