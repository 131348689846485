import * as Actions from "./actionTypes";
import ajax from "helpers/ajax";
import {ReactNode} from "react";
import {ThunkAction} from "model/store/thunk";
import {ShopState} from "helpers/reducers";
import {toast} from "react-toastify";
import SessionAction from "store/session/SessionAction";
import {consoleLog} from "utils/utility";
import {ShopArticleItem} from "types/entities/ShopArticleItem";
import {ShopArticleItemsCart} from "types/entities/ShopArticleItemsCart";

export function postSupportMessage(data: {name: string; email: string; subject: string; message: string}): ThunkAction<Promise<Response>> {
    return async (dispatch, getState): Promise<Response> => {
        const session = getState().sessionState.session;
        return await ajax.post("/app/post-support-message", data, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${session.access_token}`,
            },
        });
    };
}

export function fetchAssertPayment(assert, token) {
    return (dispatch, getState): Promise<any> => {
        const session = getState().sessionState.session;
        let url = "/shop/pay?assert=" + +assert;
        if (typeof token !== "undefined") {
            url += "&token=" + token;
        }
        return ajax
            .get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            })
            .then(r => {
                console.log(r);
                return r;
            });
    };
}

export function confirmCart(redirectUrl: string) {
    return (dispatch, getState): Promise<any> => {
        const cart: ShopState = getState().shopState;
        const profile = getState().sessionState.profile;
        const session = getState().sessionState.session;
        if (!cart) {
            toast.error("Internal error. Contact support");
            return Promise.reject();
        }
        if (!profile) {
            toast.error("Internal error. Profile missing");
            return Promise.reject();
        }
        if (!session) {
            toast.error("Internal error. Session missing");
            return Promise.reject();
        }

        if (!profile.hall) {
            toast.error("Select your default hall first");
        }
        const data = {
            hall_id: profile.hall.id,
            personTypeItems: {
                adult: 0,
                child: 0,
            },
            articleItems: cart.articleItems,
            return_url: location.protocol + "//" + location.host + redirectUrl + "?assert=1",
            type: "online_profile",
        };

        return ajax
            .post("/shop/pay", data, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            })
            .then(r => {
                consoleLog(r);
                window.location.href = r.redirect;
                return r;
            });
    };
}


export function confirmGiftShopArticleItemsCart(redirectUrl: string, shop_article_items_cart: ShopArticleItemsCart) {
    return (dispatch, getState): Promise<any> => {
        const session = getState().sessionState.session;
        const data = {
            hall_id: shop_article_items_cart.hall_id,
            shop_article_items_cart_id: shop_article_items_cart.id,
            driver: shop_article_items_cart.driver,
            return_url: location.protocol + "//" + location.host + redirectUrl + "",
            type: "gift_card",
        };
        return ajax
          .post("/shop/pay", data, {
              withCredentials: true,
              headers: {
                  Authorization: `Bearer ${session.access_token}`,
              },
          })
          .then(r => {
              consoleLog(r);
              window.location.href = r.redirect;
              return r;
          });
    };
}

export function confirmShopArticleItemsCart(redirectUrl: string, shop_article_items_cart_id: number) {
    return (dispatch, getState): Promise<any> => {
        const shopState = getState().shopState;
        const profile = getState().sessionState.profile;
        const session = getState().sessionState.session;
        const articleItems = shopState.itemsCart.shopArticleItems.map((articleItem: ShopArticleItem) => {
            return {article_id: articleItem.article_id, quantity: articleItem.quantity};
        });
        const data = {
            hall_id: profile.hall.id,
            shop_article_items_cart_id: shop_article_items_cart_id,
            personTypeItems: {
                adult: 0,
                child: 0,
            },
            articleItems: articleItems,
            return_url: location.protocol + "//" + location.host + redirectUrl + "?assert=1",
            type: "online_profile",
        };
        return ajax
            .post("/shop/pay", data, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            })
            .then(r => {
                consoleLog(r);
                window.location.href = r.redirect;
                return r;
            });
    };
}
/**
 * Open modal with generic component
 * @param component
 * @param props
 */
export function openModal(component, props) {
    return dispatch => {
        dispatch(setBlur(true));
        return dispatch({
            type: Actions.OPEN_MODAL,
            component,
            props,
            isOpen: true,
        });
    };
}

export function closeModal(force?: boolean) {
    return (dispatch, getState) => {
        const modal = getState().modal;
        if (!force && modal.props?.requireCloseConfirmation === true) {
            // @todo pretty confirm
            if (!confirm("Do you want to close the form?")) {
                return false;
            }
        }
        dispatch(setBlur(false));
        return dispatch({
            type: Actions.OPEN_MODAL,
            component: null,
        });
    };
}

export function toggleModal(component, props = {}) {
    return {
        type: Actions.OPEN_MODAL,
        toggle: true,
        component,
        props,
    };
}

export function setAjaxLoading(loading, url) {
    return {
        type: Actions.AJAX_LOADING,
        loading,
        url,
    };
}
/**
 * @active
 * @param blur
 */
export function setBlur(blur) {
    return {
        type: Actions.SET_BLUR,
        blur,
    };
}

export function setError(message) {
    return {
        type: Actions.SET_MESSAGE,
        message,
        messageType: "error",
    };
}

export function setDebug(data) {
    return {
        type: Actions.SET_DEBUG,
        data: data,
    };
}

export function addFlashMessage(message, messageType) {
    return {
        type: Actions.SET_MESSAGE,
        message,
        messageType,
    };
}

export function setSuccess(message) {
    return {
        type: Actions.SET_MESSAGE,
        message,
        messageType: "success",
    };
}

/* Set Reservation Result */
export function userCheckin(): ThunkAction<Promise<Response>, any> {
    return (dispatch, getState): Promise<any> => {
        return ajax.post("/user/checkin", {}).then(r => {
            dispatch(SessionAction.setCheckin(r.driver));
            return r;
        });
    };
}
export function setMessage(message: string | ReactNode, type: string) {
    return {
        type: Actions.SET_MESSAGE,
        message,
        messageType: type,
    };
}
