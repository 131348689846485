import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "helpers/reducers";
import LanguageFlagSelector from "pages/Front/components/LanguageFlagSelector";
import CardComponent from "pages/Front/Booking/components/CardComponent";
import LanguageSelector from "pages/Front/components/LanguageSelector";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {languages} from "helpers/config";

const Language = () => {
    const hallLanguage = useTypedSelector(state => state.hallState.hall?.language?.code);
    const language = useTypedSelector(state => state.sessionState.language?.code) || hallLanguage;

    const [languageIsActive, setLanguagePopupActive] = useState(false);
    const {t} = useTranslation();
    const handleLanguageToggle = () => {
        setLanguagePopupActive(!languageIsActive);
    };
    const isFullscreen: boolean = false;

    if (!hallLanguage) {
        return null;
    }

    return (
        <>
            <button
                className="language-btn bg-white rounded-full shadow-dark p-2 2xl:p-3 w-10 h-10 2xl:w-14 2xl:h-14 flex self-end items-center justify-center"
                onClick={handleLanguageToggle}
            >
                {languageIsActive ? (
                    <span className={"flex flex-col text-3xl items-center"}>
                        <XMarkIcon className={"w-5 h-5"} />
                    </span>
                ) : (
                    <img alt="" className="img-language" src={"/img/flags/flag-" + language + ".svg"} />
                )}
            </button>
            {languageIsActive && isFullscreen && (
                <div className="isFullscreen fixed inset-0 flex flex-col bg-white z-50 h-screen p-4 sm:p-8">
                    <div className="flex flex-col justify-center items-center relative h-20">
                        <div className="max-w-7xl m-auto w-full">
                            <div className="flex flex-col text-center items-center justify-center">
                                <h3 className="font-normal text-black text-lg sm:text-3xl font-m">{t("Please select your language")}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-grow">
                        <div className="max-w-4xl m-auto w-full">
                            <div className="LanguageFlagSelector grid grid-cols-2 mx-8 sm:grid-cols-3 gap-8 sm:gap-12">
                                {languages.map(language =>
                                    <LanguageFlagSelector key={language.id} closeLanguage={handleLanguageToggle} language={language.code} name={language.name} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {languageIsActive && !isFullscreen && (
                <div className={"absolute right-0 sm:right-0 top-10 mt-8 z-30 min-w-[240px] w-full"}>
                    <CardComponent>
                        <div className="flex flex-col min-w-[120px] w-full">
                            <div className="LanguageSelectors grid space-y-3">
                                {languages.map(language =>
                                    <LanguageSelector  key={language.id} closeLanguage={handleLanguageToggle} language={language.code} name={language.name} />
                                )}
                            </div>
                        </div>
                    </CardComponent>
                </div>
            )}
        </>
    );
};
export default Language;
