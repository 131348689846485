import React from "react";
import {ROOT_STORAGE_WEB} from "helpers/config";

interface ServerImageProps {
    src: string;
    className?: string;
    alt: string;
}

export const ServerImage = (props: ServerImageProps) => {
    let src = props.src;
    if (!src) {
        return null;
    }


    const alternatePicture = props.src.includes("winti.tomkart.com");
    const replacePictureUrl = (src: string) => {
        switch (src) {
            case "https://winti.tomkart.com/svg/adult-helmet.svg":
                return "/img/Blue-Racing-Helmet.png";
            case "https://winti.tomkart.com/svg/child-helmet.svg":
                return "/img/Green-Racing-Helmet.png";
            default:
                return "/img/Blue-Racing-Helmet.png";
        }
    };

    if (typeof src === "string") {
        if (src.indexOf("https:") === -1 && src.indexOf("/img/") === -1) {
            src = ROOT_STORAGE_WEB + "storage/" + src;
        }
    }

    return <img alt={props.alt} className={props.className ? props.className : "w-full h-full object-cover"}
                src={alternatePicture ? replacePictureUrl(src) : src} />;
};
