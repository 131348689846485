import {createAction} from "helpers/createAction";
import ajax from "helpers/ajax";
import {VoucherItem} from "types/entities/VoucherItem";
import {PackagesCart} from "types/entities/PackagesCart";
import { ThunkAction } from "model/store/thunk";
import {
    VoucherReducerApplyVoucherItemsAction,
    VoucherReducerDeleteAppliedVoucherItemsAction,
    VoucherReducerDeleteVoucherItemsAction,
    VoucherReducerPushVoucherItemsAction,
    VoucherReducerResetAppliedVoucherItemsAction,
    VoucherReducerResetVoucherItemsAction,
    VoucherReducerSelectVoucherItemAction, VoucherReducerSetAllVouchersItemsAction,
    VoucherReducerSetVoucherItemsAction
} from "store/shop/VoucherReducer";
import { ShopArticleItemsVouchersResponse } from "types/entities/ShopArticleItemsVouchersResponse";
import { ShopArticleItemsVouchersRequest } from "types/entities/ShopArticleItemsVouchersRequest";
import { ShopArticleItemsCart } from "types/entities/ShopArticleItemsCart";
import { ShopArticleItemsVoucher } from "types/entities/ShopArticleItemVouchers";

export interface VoucherResponse {
    voucher: VoucherItem | null;
    packageCart?: PackagesCart;
    shopArticleItemsVouchers?: ShopArticleItemsVoucher[];
    shopArticleItemsCart?: ShopArticleItemsCart;
}

interface VouchersResponse {
    vouchers: VoucherItem[] | [];
}

class VoucherAction {
    static SELECT_VOUCHER_ITEM = "VoucherAction.SelectVoucherItem";
    static PUSH_VOUCHER_ITEMS = "VoucherAction.PushVoucherItems";
    static SET_VOUCHER_ITEM = "VoucherAction.SetVoucherItem";
    static SET_ALL_VOUCHERS_ITEMS = "VoucherAction.SetAllVouchersItems";
    static DELETE_VOUCHER_ITEM = "VoucherAction.DeleteVoucherItem";
    static DELETE_APPLIED_VOUCHER_ITEM = "VoucherAction.DeleteAppliedVoucherItem";
    static APPLY_VOUCHER_ITEMS = "VoucherAction.ApplyVoucherItems";
    static RESET_VOUCHER = "VoucherAction.ResetVoucher";
    static RESET_APPLIED_VOUCHER = "VoucherAction.ResetAppliedVoucher";



    static selectVoucherItem = (voucherItem: VoucherItem): VoucherReducerSelectVoucherItemAction => {
        return createAction(VoucherAction.SELECT_VOUCHER_ITEM, voucherItem);
    }

    static pushVoucherItem = (voucherItem: VoucherItem): VoucherReducerPushVoucherItemsAction => {
        return createAction(VoucherAction.PUSH_VOUCHER_ITEMS, voucherItem);
    };

    static setVoucherItem = (voucherItem: VoucherItem): VoucherReducerSetVoucherItemsAction => {
        return createAction(VoucherAction.SET_VOUCHER_ITEM, voucherItem);
    };

    static deleteVoucherItem = (voucherItem: VoucherItem): VoucherReducerDeleteVoucherItemsAction => {
        return createAction(VoucherAction.DELETE_VOUCHER_ITEM, voucherItem);
    };

    static deleteAppliedVoucherItem = (voucherItem: VoucherItem): VoucherReducerDeleteAppliedVoucherItemsAction => {
        return createAction(VoucherAction.DELETE_APPLIED_VOUCHER_ITEM, voucherItem);
    };

    static applyVoucherItem = (voucherItem: VoucherItem): VoucherReducerApplyVoucherItemsAction => {
        return createAction(VoucherAction.APPLY_VOUCHER_ITEMS, voucherItem);
    };

    static resetVouchers = (): VoucherReducerResetVoucherItemsAction => {
        return createAction(VoucherAction.RESET_VOUCHER, "");
    };

    static resetAppliedVouchers = (): VoucherReducerResetAppliedVoucherItemsAction => {
        return createAction(VoucherAction.RESET_APPLIED_VOUCHER, "");
    };

    static setVoucherItems = (voucherItems: VoucherItem[]): VoucherReducerSetAllVouchersItemsAction => {
        return createAction(VoucherAction.SET_ALL_VOUCHERS_ITEMS, voucherItems);
    };

    static fetchDeleteVoucher = (packagesCart: PackagesCart, data: any): ThunkAction<Promise<VoucherResponse>> => {
        return (dispatch): Promise<any> => {
            return ajax
                .post("/booking/cart/" + packagesCart.id + "/" + packagesCart.token + "/delete-voucher", {
                    code: data.code,
                })
                .then(r => {
                    return r;
                })
                .catch(err => {
                    return err;
                });
        };
    };

    static fetchAllVouchers = (): ThunkAction<Promise<VouchersResponse>> => {
        return async (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;
            try {
                const r = await ajax.get("/user/vouchers", {
                      withCredentials: true,
                      headers: {
                          Authorization: `Bearer ${session.access_token}`
                      }
                  }
                );
                return r;
            } catch (err) {
                return err;
            }
        }
    }

    static fetchGetVoucher = (data: any): ThunkAction<Promise<VoucherResponse>> => {
        return (dispatch): Promise<any> => {
            return ajax
                .get("/articles/voucher?code=" + data.code.trim())
                .then(r => {
                    return r;
                })
                .catch(err => {
                    return err;
                });
        };
    };

    static fetchDeleteShoppingCartVoucher = (data: ShopArticleItemsVouchersRequest): ThunkAction<Promise<VoucherResponse>> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;
            return ajax
                .post(
                    "/shop/delete-voucher",
                    {
                        shop_article_items_cart_id: data.shop_article_items_cart_id,
                        code: data.code.trim(),
                    },
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${session.access_token}`,
                        },
                    },
                )
                .then(r => {
                    return r;
                }).catch(err => {
                });
        };
    };

    static fetchApplyShoppingCartVoucher = (data: ShopArticleItemsVouchersRequest): ThunkAction<Promise<void|ShopArticleItemsVouchersResponse>> => {
        return (dispatch, getState): Promise<void|ShopArticleItemsVouchersResponse> => {
            const session = getState().sessionState.session;
            return ajax
                .post<void|ShopArticleItemsVouchersResponse>(
                    "/shop/apply-voucher-to-shop-article-items-cart",
                    {
                        code: data.code.trim(),
                        shop_article_items_cart_id: data.shop_article_items_cart_id,
                        hall_id: data.hall_id,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${session.access_token}`,
                        },
                    },
                )
                .then((r: void|ShopArticleItemsVouchersResponse) => {
                    return r;
                }).catch(err => {
                });
        };
    };

    static fetchApplyVoucher = (packagesCart: PackagesCart, data: any): ThunkAction<Promise<VoucherResponse>> => {
        return (dispatch): Promise<any> => {
            return ajax
                .post("/booking/cart/" + packagesCart.id + "/" + packagesCart.token + "/apply-voucher", {
                    code: data.code.trim(),
                })
                .then(r => {
                    return r;
                })
                .catch(err => {
                    return err;
                });
        };
    };
}

export default VoucherAction;
