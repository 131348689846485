import BookingAction, {PackageUpsell, ReservationNote} from "store/booking/BookingAction";
import {Activity} from "types/entities/Activity";
import update from "immutability-helper";
import {Package} from "types/entities/Package";
import {BookingFilter, BookingProcess, BookingTimeslot} from "types/app";
import Dates from "utils/Dates";
import {Company} from "types/entities/Company";
import {PackagesCart} from "types/entities/PackagesCart";
import {Cookies} from "types/entities/Cookies";
import {Cookies_status} from "types/enums/Cookies_status";
import {Payment} from "types/entities/Payment";

export interface BookingReducerSetActivitiesAction {
    payload: Activity[];
    type: typeof BookingAction.SET_ACTIVITIES;
}

export interface BookingReducerSetPackagesAction {
    payload: Package[];
    type: typeof BookingAction.SET_PACKAGES;
}

export interface BookingReducerSetTimeslotsAction {
    payload: BookingTimeslot[];
    type: typeof BookingAction.SET_TIMESLOTS;
}

export interface BookingReducerSetTimeslotsAllowedAction {
    payload: boolean;
    type: typeof BookingAction.SET_TIMESLOTS_ALLOWED;
}

export interface BookingReducerSetPackageCartAction {
    payload: PackagesCart;
    type: typeof BookingAction.SET_PACKAGE_CART;
}

export interface BookingReducerSetFilterAction {
    payload: BookingFilter;
    type: typeof BookingAction.SET_FILTER;
}

export interface BookingReducerSetPackageAction {
    payload: Package | null;
    type: typeof BookingAction.SET_PACKAGE;
}


export interface BookingReducerPushReservationNoteAction {
    payload: ReservationNote;
    type: typeof BookingAction.PUSH_RESERVATION_NOTE;
}

export interface BookingReducerResetReservationNoteAction {
    payload: null | ReservationNote;
    type: typeof BookingAction.RESET_RESERVATION_NOTE;
}


export interface BookingReducerPushUpsellAction {
    payload: PackageUpsell;
    type: typeof BookingAction.PUSH_UPSELL;
}

export interface BookingReducerSetUpsellAction {
    payload: PackageUpsell;
    type: typeof BookingAction.SET_UPSELL;
}

export interface BookingReducerRemoveUpsellAction {
    payload: PackageUpsell;
    type: typeof BookingAction.REMOVE_UPSELL;
}

export interface BookingReducerSetCustomerAction {
    payload: Company;
    type: typeof BookingAction.SET_CUSTOMER;
}

export interface BookingReducerSetCookiesAction {
    payload: Cookies;
    type: typeof BookingAction.SET_COOKIES;
}

export interface BookingReducerSetPackagesCartPaymentAction {
    payload: Payment | null;
    type: typeof BookingAction.SET_PACKAGES_CART_PAYMENT;
}

export interface BookingReducerSetCurrentNavigationStepAction {
    payload: BookingNavigationStep;
    type: typeof BookingAction.SET_CURRENT_NAVIGATION_STEP;
}

export interface BookingReducerClearBookingProcessAction {
    payload: null;
    type: typeof BookingAction.CLEAR_BOOKING_PROCESS_STATE;
}

export interface BookingReducerClearUpsellsAction {
    payload: any[];
    type: typeof BookingAction.CLEAR_UPSELLS;
}

type BookingReducerAction =
    | BookingReducerSetActivitiesAction
    | BookingReducerSetPackagesAction
    | BookingReducerSetTimeslotsAction
    | BookingReducerSetTimeslotsAllowedAction
    | BookingReducerSetPackageCartAction
    | BookingReducerSetFilterAction
    | BookingReducerSetPackageAction
    | BookingReducerPushReservationNoteAction
    | BookingReducerResetReservationNoteAction
    | BookingReducerSetUpsellAction
    | BookingReducerPushUpsellAction
    | BookingReducerRemoveUpsellAction
    | BookingReducerSetCustomerAction
    | BookingReducerSetCookiesAction
    | BookingReducerSetPackagesCartPaymentAction
    | BookingReducerSetCurrentNavigationStepAction
    | BookingReducerClearBookingProcessAction
    | BookingReducerClearUpsellsAction;

export interface BookingNavigation {
    currentStep: BookingNavigationStep,
    timeslotsAllowedSteps: BookingNavigationStep[]
    timeslotsNotAllowedSteps: BookingNavigationStep[]
}

export interface BookingNavigationStep {
    id: number,
    label: string
}


const TIMESLOTS_ALLOWED_STEPS = [
    {id: 1, label: "Packages"},
    {id: 2, label: "Extras"},
    {id: 3, label: "Time"},
    {id: 4, label: "Your details"},
    {id: 5, label: "Recapitulation"},
    {id: 6, label: "Finish"},
];

const TIMESLOT_NOT_ALLOWED_STEPS = [
    {id: 1, label: "Packages"},
    {id: 2, label: "Customer"},
    {id: 3, label: "Paid"},
];

export interface BookingReducerState {
    activities: Activity[];
    packages: Package[];
    timeslotsAllowed: boolean;
    timeslots: BookingTimeslot[];
    bookingProcess: BookingProcess;
    cookies: Cookies;
    navigation: BookingNavigation;
}

const initBookingProcess = {
    filter: {
        adults: 0,
        children: 0,
        date: Dates.ymd(new Date()),
        activity_ids: [],
        groupType: [],
        hours_count: 0,
    },
    package: null,
    upsell: [],
    customer: null,
    packageCart: null,
    voucher: null,
    payment: null,
    notes: [],
};

class BookingReducer {
    initialState: BookingReducerState = {
        activities: [],
        packages: [],
        timeslotsAllowed: false,
        timeslots: [],
        bookingProcess: initBookingProcess,
        cookies: {tech_cookies: true, analytics_cookies: false, advert_cookies: false, status: Cookies_status.NULL},
        navigation: {
            currentStep: TIMESLOTS_ALLOWED_STEPS[0],
            timeslotsAllowedSteps: TIMESLOTS_ALLOWED_STEPS,
            timeslotsNotAllowedSteps: TIMESLOT_NOT_ALLOWED_STEPS,
        },
    };

    reducer = (state: BookingReducerState = this.initialState, action: BookingReducerAction): BookingReducerState => {
        switch (action.type) {
            case BookingAction.SET_ACTIVITIES:
                return update(state, {
                    activities: {
                        $set: action.payload,
                    },
                });
            case BookingAction.SET_PACKAGES:
                return update(state, {
                    packages: {
                        $set: action.payload,
                    },
                });
            case BookingAction.SET_TIMESLOTS_ALLOWED:
                return update(state, {
                    timeslotsAllowed: {
                        $set: action.payload,
                    },
                });
            case BookingAction.SET_TIMESLOTS:
                return update(state, {
                    timeslots: {
                        $set: action.payload,
                    },
                });

            case BookingAction.SET_PACKAGE_CART:
                return update(state, {
                    bookingProcess: {
                        packageCart: {
                            $set: action.payload,
                        },
                    },
                });
            case BookingAction.SET_FILTER:
                action.payload.adults = +action.payload.adults;
                action.payload.children = +action.payload.children;
                return update(state, {
                    bookingProcess: {
                        filter: {
                            $set: action.payload,
                        },
                    },
                });
            case BookingAction.SET_PACKAGE:
                return update(state, {
                    bookingProcess: {
                        package: {
                            $set: action.payload,
                        },
                    },
                });
            case BookingAction.RESET_RESERVATION_NOTE:
                return update(state, {
                    bookingProcess: {
                        notes: {
                            $set: [
                                ...initBookingProcess.notes,
                            ],
                        },

                    },
                });

            case BookingAction.PUSH_RESERVATION_NOTE:
                const notesIndex = state.bookingProcess.notes.findIndex(note => note.id === action.payload.id);
                if (notesIndex !== -1) {
                    return update(state, {
                        bookingProcess: {
                            notes: {
                                [notesIndex]: {
                                    $set: action.payload,
                                },
                            },
                        },
                    });
                }
                return update(state, {
                    bookingProcess: {
                        notes: {
                            $push: [action.payload],
                        },
                    },
                });

            case BookingAction.SET_UPSELL:
                const upsellFoundIndex = state.bookingProcess.upsell.findIndex(upsell => upsell.packageItem.id === action.payload.packageItem.id);
                if (upsellFoundIndex !== -1) {
                    return update(state, {
                        bookingProcess: {
                            upsell: {
                                [upsellFoundIndex]: {
                                    $set: action.payload,
                                },
                            },
                        },
                    });
                }
                return state;

            case BookingAction.PUSH_UPSELL:
                const upsellIndex = state.bookingProcess.upsell.findIndex(upsell => upsell.packageItem.id === action.payload.packageItem.id);

                if (upsellIndex !== -1) {
                    return update(state, {
                        bookingProcess: {
                            upsell: {
                                [upsellIndex]: {
                                    $set: action.payload,
                                },
                            },
                        },
                    });
                }

                return update(state, {
                    bookingProcess: {
                        upsell: {
                            $push: [action.payload],
                        },
                    },
                });

            case BookingAction.REMOVE_UPSELL:
                const filteredUpsells = state.bookingProcess.upsell?.filter(upsell => upsell.packageItem.id !== action.payload.packageItem.id);

                return update(state, {
                    bookingProcess: {
                        upsell: {
                            $set: filteredUpsells,
                        },
                    },
                });

            case BookingAction.SET_CUSTOMER:
                return update(state, {
                    bookingProcess: {
                        customer: {
                            $set: action.payload,
                        },
                    },
                });

            case BookingAction.SET_COOKIES:
                return update(state, {
                    cookies: {
                        $set: action.payload,
                    },
                });

            case BookingAction.SET_PACKAGES_CART_PAYMENT:
                return update(state, {
                    bookingProcess: {
                        payment: {
                            $set: action.payload,
                        },
                    },
                });

            case BookingAction.SET_CURRENT_NAVIGATION_STEP:
                return update(state, {
                    navigation: {
                        currentStep: {
                            $set: action.payload,
                        },
                    },
                });

            case BookingAction.CLEAR_BOOKING_PROCESS_STATE:
                return update(state, {
                    bookingProcess: {
                        $set: {
                            ...initBookingProcess,
                        },
                    },
                });
            case BookingAction.CLEAR_UPSELLS:
                return update(state, {
                    bookingProcess: {
                        upsell: {
                            $set: [],
                        },
                    },
                });

            default:
                return state;
        }
    };
}

export default BookingReducer;
