import {VoucherDiscountType, VoucherItem, VoucherType, VoucherTypeFilter} from "types/entities/VoucherItem";
import {Package} from "types/entities/Package";
import {TicketType} from "types/enums/TicketType";
import {currencyNumberFormat, getArticleById} from "utils/utility";
import {PackageCategory} from "types/enums/PackageCategory";
import Dates from "utils/Dates";
import {ArticleItem, DiscountedArticleItemPrice} from "types/entities/ArticleItem";
import {DateRange} from "types/entities/PackageItem";
import {VoucherItemValidity} from "types/entities/specific/VoucherItemValidity";
import {ShopArticleItem} from "types/entities/ShopArticleItem";

export const voucherTypeByArticle = (voucherItem: VoucherItem): VoucherType => {
    if (!voucherItem.article) {
        return {
            label: "General",
            type: VoucherDiscountType.General,
            valueFormatted: "" + voucherItem.amount_left,
            filterBy: [VoucherTypeFilter.All],
            value: voucherItem.amount_left,
        };
    }

    switch (voucherItem.article.ticket_type) {
        case TicketType.Race:
            return {
                label: "Activity discount",
                type: VoucherDiscountType.Race,
                valueFormatted: voucherItem.article.race_count_total + "x",
                value: voucherItem.article.race_count_total,
                filterBy: [VoucherTypeFilter.Article, VoucherTypeFilter.Activity],
            };

        case TicketType.Discount:
            return {
                label: "Total discount",
                type: VoucherDiscountType.Value,
                valueFormatted: currencyNumberFormat(voucherItem.article.article_value),
                value: voucherItem.article.article_value,
                filterBy: voucherItem.article.activity_id ? [VoucherTypeFilter.Activity] : [VoucherTypeFilter.All],
            };

        case TicketType.DiscountPercent:
            return {
                label: "Percent discount",
                type: VoucherDiscountType.Percent,
                valueFormatted: voucherItem.article.article_value / 100 + "%",
                value: voucherItem.article.article_value / 100,
                filterBy: voucherItem.article.activity_id ? [VoucherTypeFilter.Activity] : [VoucherTypeFilter.All],
            };

        case TicketType.Voucher:
            return {
                label: "Credit",
                type: VoucherDiscountType.Credit,
                valueFormatted: currencyNumberFormat(voucherItem.amount_left),
                value: voucherItem.amount_left,
                filterBy: voucherItem.article.activity_id ? [VoucherTypeFilter.Activity] : [VoucherTypeFilter.All],
            };

        default:
            return {
                label: "General",
                type: VoucherDiscountType.General,
                valueFormatted: "" + voucherItem.amount_left,
                value: voucherItem.amount_left,
                filterBy: [VoucherTypeFilter.All],
            };
    }
};

export const voucherEligibleForPackages = (voucherItem: VoucherItem) => {
    return voucherItem.article.ticket_type === TicketType.Race;
};

export const voucherEligibleForActivity = (voucherItem: VoucherItem) => {
    return voucherItem.article.activity_id !== null;
};

export const compareNumbersInArray = (a: number[], b: number[]) => a.filter(e => b.indexOf(e) !== -1);

export const isAlreadyApplied = (appliedVoucherItems, voucherCode) => appliedVoucherItems.filter(appliedItem => appliedItem.code === voucherCode);

export const flatPackages = (packages: Package[]) => packages.map(Package => [...Package.packageItems]).flat();

export const getEligibleActivityFromVouchers = (voucherItems: VoucherItem[], packages: Package[]) => {
    if (voucherItems.length === 0 || packages.length === 0) {
        return null;
    }
    const eligibleVouchers = voucherItems.filter(voucherItem => voucherEligibleForActivity(voucherItem));
    if (eligibleVouchers.length === 0) {
        return null;
    }

    const filteredNonEventPackages = packages.filter(Package => Package.category !== PackageCategory.Event);
    const packageItems = flatPackages(filteredNonEventPackages);
    const eligibleVouchersActivityIds: number[] = eligibleVouchers
        .filter(voucherItem => voucherItem.article.activity_id)
        .map(voucherItem => voucherItem.article.activity_id) as number[];
    const packageItemActivityIds = packageItems.map(packageItem => packageItem.activity_id);
    const eligiblePackageItems = compareNumbersInArray(packageItemActivityIds, eligibleVouchersActivityIds);
    if (!eligiblePackageItems) {
        return null;
    }
    const filteredPackageItems = packageItems.filter(packageItem => {
        return eligiblePackageItems.includes(packageItem.activity_id);
    });

    return filteredPackageItems.map(packageItem => packageItem.package_id);
};

export const getEligibleDiscountsFromVouchers = (voucherItems: VoucherItem[], packages: Package[]) => {
    if (voucherItems.length === 0 || packages.length === 0) {
        return null;
    }
    const eligibleVouchers = voucherItems.filter(voucherItem => {
        return voucherTypeByArticle(voucherItem).type === VoucherDiscountType.Percent || voucherTypeByArticle(voucherItem).type === VoucherDiscountType.Value;
    });
    if (eligibleVouchers.length === 0) {
        return null;
    }

    const filteredNonEventPackages = packages.filter(Package => Package.category !== PackageCategory.Event);
    const packageItems = flatPackages(filteredNonEventPackages);
    // const eligibleVouchersActivityIds: number[] = eligibleVouchers.filter(voucherItem => voucherItem.article.activity_id).map(voucherItem => voucherItem.article.activity_id) as number[];
    // const packageItemActivityIds = packageItems.map(packageItem => packageItem.activity_id);
    // const eligiblePackageItems = compareNumbersInArray(packageItemActivityIds, eligibleVouchersActivityIds);
    // if (!eligiblePackageItems) {
    //     return null;
    // }

    return packageItems.map(packageItem => packageItem.package_id);
};

export const getEligiblePackageFromVoucherItem = (voucherItem: VoucherItem, Package: Package): boolean => {
    if (typeof voucherItem === "undefined") {
        return false;
    }
    const filteredNonEventPackages = Package.category !== PackageCategory.Event;
    if (!filteredNonEventPackages) {
        return false; //Only non-Event packages are eligible to any discounts
    }
    const packageItems = Package.packageItems.flat();
    const packageItemArticleIds = packageItems.map(packageItem => packageItem.article_id);
    const packageItemActivities = packageItems.map(packageItem => packageItem.activity_id);
    if (!packageItemArticleIds.includes(voucherItem.article_id)) {
        if (voucherItem.article.activity_id !== null && typeof voucherItem.article.activity_id !== "undefined") {
            if (!packageItemActivities.includes(voucherItem.article.activity_id)) {
                return false; //if the voucher is tied to activity, and the packageItems don't include this activity
            } else {
                if (voucherTypeByArticle(voucherItem).filterBy.includes(VoucherTypeFilter.Activity)) {
                    return true; // activity is included
                } else {
                    return false; // activity is included, but the voucher isn' tied to this activity
                }
            }
        } else {
            if (voucherTypeByArticle(voucherItem).filterBy.includes(VoucherTypeFilter.All)) {
                return true; // voucher is of generic type, it's allowed everywhere
            }
            return false; //article isn't included
        }
    } else {
        return true; //article is included
    }
};

export const getEligiblePackagesFromVouchers = (voucherItems: VoucherItem[], packages: Package[]) => {
    if (voucherItems.length === 0 || packages.length === 0) {
        return null;
    }
    const eligibleVouchers = voucherItems.filter(voucherItem => voucherEligibleForPackages(voucherItem));
    if (eligibleVouchers.length === 0) {
        return null;
    }

    const filteredNonEventPackages = packages.filter(Package => Package.category !== PackageCategory.Event);
    const packageItems = flatPackages(filteredNonEventPackages);
    const eligibleVouchersArticleIds = eligibleVouchers.map(voucherItem => voucherItem.article_id);
    const packageItemArticleIds = packageItems.map(packageItem => packageItem.article_id);

    const eligiblePackageItems = compareNumbersInArray(packageItemArticleIds, eligibleVouchersArticleIds);
    if (!eligiblePackageItems) {
        return null;
    }

    const filteredPackageItems = packageItems.filter(packageItem => {
        return eligiblePackageItems.includes(packageItem.article_id);
    });

    return filteredPackageItems.map(packageItem => packageItem.package_id);
};

export const getAppliedDiscountValue = (voucherItem: VoucherItem) => {
    return voucherTypeByArticle(voucherItem);
};

export const getValidityByWeekDay = (voucherItem: VoucherItem, date: string): VoucherItemValidity => {
    const includeWeekDays = voucherItem.extra.includeWeekDays;
    const excludeWeekDays = voucherItem.extra.excludeWeekDays;
    const limited = includeWeekDays.length > 0 || excludeWeekDays.length > 0;
    const weekDay = Dates.weekDay(date);
    const dateRangeValidity = getValidDateRangeByFilterDate(voucherItem, date);
    const includeWeekDayNames = includeWeekDays.map(weekDay => Dates.weekdayNameTranslated(weekDay));
    const excludeWeekDayNames = excludeWeekDays.map(weekDay => Dates.weekdayNameTranslated(weekDay));
    let include = true;
    let exclude = true;
    let notExpired = true;
    if (includeWeekDays.length > 0 && !includeWeekDays.includes(weekDay)) {
        include = false;
    }

    if (excludeWeekDays.length > 0 && excludeWeekDays.includes(weekDay)) {
        exclude = false;
    }

    const expires_at = voucherItem.expires_at !== null ? new Date(voucherItem.expires_at) : null;
    if (expires_at !== null) {
        if (expires_at < new Date(date)) {
            notExpired = false;
        }
    }

    const valid = include && exclude && notExpired;
    return {
        limited: limited,
        validity: valid,
        includeWeekDays: includeWeekDayNames,
        excludeWeekDays: excludeWeekDayNames,
        excludeDateRange: dateRangeValidity.excluded,
        excludeDateRanges: dateRangeValidity.excludeDateRanges,
        includeDateRange: dateRangeValidity.included,
        includeDateRanges: dateRangeValidity.includeDateRanges,
    };
};

export const getDiscountTotalPrice = (articleItems: ArticleItem[]): number => {
    return articleItems.reduce((agg, cur: ArticleItem) => {
        if (cur.appliedVoucher) {
            const discount = cur.appliedVoucher.old_price - cur.appliedVoucher.new_price;
            return agg + discount;
        }
        return agg;
    }, 0);
};
export const getDiscountedArticleItemPrice = (articleItem: ArticleItem | ShopArticleItem): DiscountedArticleItemPrice => {
    const articleItemArticle = getArticleById(articleItem.article_id);
    if ("appliedVoucher" in articleItem && articleItem.appliedVoucher) {
        return {
            present: true,
            applied: articleItem.appliedVoucher.applicable,
            price: articleItem.appliedVoucher.new_price,
        };
    }
    return {
        present: false,
        applied: false,
        price: articleItemArticle.article_value,
    };
};

export const eligibleVoucherFilterBy = (eligibleVoucher: VoucherItem, voucherTypeFilter: VoucherTypeFilter): boolean =>
    voucherTypeByArticle(eligibleVoucher).filterBy.includes(voucherTypeFilter);

export const getValidDateRangeByFilterDate = (eligibleVoucher: VoucherItem, date: string) => {
    const filterDate = new Date(date);
    const excludeDateRanges = eligibleVoucher.extra.excludeDateRange;
    const includeDateRanges = eligibleVoucher.extra.includeDateRange;

    const isExcluded = excludeDateRanges.reduce((prev: boolean, cur: DateRange) => {
        const agg = filterDate <= new Date(cur.from) || filterDate >= new Date(cur.to);
        if (!prev) {
            return agg;
        }
        return prev && agg;
    }, false);

    const isIncluded = includeDateRanges.reduce((prev: boolean, cur: DateRange) => {
        const agg = filterDate >= new Date(cur.from) && filterDate <= new Date(cur.to);
        if (!prev) {
            return agg;
        }
        return prev && agg;
    }, false);

    return {
        includeDateRanges: includeDateRanges,
        included: isIncluded,
        excludeDateRanges: excludeDateRanges,
        excluded: isExcluded,
    };
};

export const getDateRangeValidity = (eligibleVoucher: VoucherItem, date: string): boolean => {
    if (eligibleVoucher.extra.includeDateRange.length === 0 && eligibleVoucher.extra.excludeDateRange.length === 0) {
        return true;
    }
    const dateRangeValidity = getValidDateRangeByFilterDate(eligibleVoucher, date);
    console.log(dateRangeValidity);
    return dateRangeValidity.included && !dateRangeValidity.excluded;
};
