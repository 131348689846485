import { hot } from "react-hot-loader";
import AlertResponder from "components/AlertResponder";
import Loader from "components/Loader";
import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import LogRocket from "logrocket";
import FrontLayout from "./layouts/FrontLayout";
import { DEFAULT_HALL, IS_PROD } from "helpers/config";
import { HallCode } from "types/enums/HallCode";


if(IS_PROD) {
    if(DEFAULT_HALL === HallCode.Winterthur || DEFAULT_HALL === HallCode.Spreitenbach) {
        LogRocket.init('kartch-booking/bookingkart');
    } else {
        if(new Date().getDay() > 3) {
            LogRocket.init('uebhwc/speeder');
        } else {
            LogRocket.init('zwolle/booking1');
        }
    }
}

class App extends React.Component {
    state = {
        error: null,
    };

    render() {

        return (
            <div className="App h-full w-full flex flex-col">
                <>
                    <Loader usereducer={true} />
                    <Switch>
                        <Route path={"/"}>
                            <FrontLayout />
                        </Route>
                    </Switch>
                    <AlertResponder />
                </>
            </div>
        );
    }
}

export default hot(module)(App);
