import {consoleLog} from "utils/utility";

const clearStoreOnUpdate = (): void => {
    const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;

    if (typeof localStorage.APP_VERSION === "undefined" || localStorage.APP_VERSION === null) {
        localStorage.setItem("APP_VERSION", APP_VERSION || "unknown");
    }

    consoleLog("Local current version: ", localStorage.APP_VERSION, ", new version number: ", APP_VERSION);

    if (localStorage.APP_VERSION && localStorage.APP_VERSION !== "unknown" && localStorage.APP_VERSION != APP_VERSION) {
        localStorage.clear();

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(names => {
                for (const name of names) {
                    caches.delete(name);
                }
            });
        }

        // delete browser cache and hard reload
        window.location.reload();
    }
};
export default clearStoreOnUpdate;
