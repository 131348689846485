import {Package} from "types/entities/Package";
import {ArticleList} from "./ArticleList";
import {HallReservationSlot} from "./HallReservationSlot";
import {Activity} from "types/entities/Activity";
import {PackageItemPersonType} from "types/enums/PackageItemPersonType";
import {Translations} from "helpers/config";

export enum PackageItemItemType {
    PackageItem = "package_item",
    Upsell = "upsell",
    BasePrice = "base_price",
}

export interface DateRange {
    from: string;
    to: string;
}

export interface PackageItemExtra {
    whole_group: boolean;
    max_count: number;
    upsell_option: number;
    extra_heat: boolean;
    group: number;
    excludeDateRange: DateRange[];
    includeDateRange: DateRange[];
    includeWeekDays: number[];
    excludeWeekDays: number[];
}

export interface PackageItem {
    id: number;
    guid: string;
    package_id: number;
    article_id: number;
    position: number | null;
    name: Translations;
    description: Translations;
    image: string | null;
    price: number;
    person_type: PackageItemPersonType | null;
    extra: PackageItemExtra;
    type: PackageItemItemType;
    minimum_persons_count: number;
    maximum_persons_count: number;
    activity_id: number;
    duration: number;
    is_active: number;
    package?: Package | null;
    article: ArticleList;
    activity?: Activity | null;
    hallReservationSlots?: HallReservationSlot[] | null;
}
