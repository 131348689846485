import {IS_PROD} from "helpers/config";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./assets/main.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "helpers/store";
import {Provider} from "react-redux";
//import AlertTemplate from "components/AlertTemplate";
import history from "helpers/history";
import {Router} from "react-router-dom";
import * as Sentry from "@sentry/react";
import SuspenseLoader from "components/SuspenseLoader";
import matchAll from "string.prototype.matchall";
import "helpers/i18n";
import {Integrations} from "@sentry/tracing";

if (IS_PROD) {
    const SENTRY_RELEASE = process.env.REACT_SENTRY_PROJECT + "@" + process.env.REACT_APP_VERSION;

    Sentry.init({
        dsn: "https://f3dab1d543b442008acc663121b2ee4a@o1152451.ingest.sentry.io/4504921217695744",
        release: SENTRY_RELEASE,
        ignoreErrors: [/Non-Error promise rejection captured with keys: config, dat/, /Unexpected token '<'/, /Illegal invocation/],
        integrations: [
            new Sentry.BrowserTracing({
                // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
        ],

        tracesSampleRate: 0.1,
    });

}

matchAll.shim();

const Root: React.FC = () => {
    return (
        <Suspense fallback={<SuspenseLoader />}>
            <Provider store={store}>
                <Router history={history}>
                    <App />
                </Router>
            </Provider>
        </Suspense>
    );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
