import ajax from "helpers/ajax";
import {createAction} from "helpers/createAction";
import { ThunkAction } from "model/store/thunk";
import {SessionData} from "types/responses/SessionResponse";
import {UserResponse} from "pages/Front/Profile/components/Forms/UpdateLanguageForm";
import {consoleLog} from "utils/utility";
import {PeriodType} from "types/entities/PeriodType";
import i18next from "i18next";
import { Notification } from "types/entities/Notification";
import { ReducerSessionPushNotificationAction } from "store/session/SessionReducer";

export interface RegistrationData {
    name: string;
    surname: string;
    birthdate: string;
    email: string;
    experience: string;
    username: string;
}

class SessionAction {
    static readonly SET_NOTIFICATIONS = "SessionAction.SetNotifications";
    static readonly PUSH_NOTIFICATION = "SessionAction.PushNotification";
    static readonly SET_SESSION = "SessionAction.SetSession";
    static readonly SET_PROFILE = "SessionAction.SetProfile";
    static readonly SET_TICKETS = "SessionAction.SetTickets";
    static readonly SET_RESULT = "SessionAction.SetResult";
    static readonly SET_RESULTS = "SessionAction.SetResults";
    static readonly SET_RANKING = "SessionAction.SetRanking";
    static readonly SET_MYSTATS = "SessionAction.SetMyStats";
    static readonly SET_CHECKIN = "SessionAction.SetCheckin";
    static readonly SET_LANGUAGE = "SessionAction.SetLanguage";

    static readonly RESET = "SessionAction.Reset";

    static register = (data: RegistrationData): ThunkAction<Promise<any>, any> => {
        return (dispatch): Promise<any> => {
            return ajax.post("/user/register", data).then(r => {
                // dispatch(SessionAction.setSession(r));
                // history.push("/profile");
                return r.user;
            });
        };
    };

    static login = (data: {username: string; password: string}): ThunkAction<Promise<Response>, any> => {
        return (dispatch): Promise<any> => {
            return ajax.post("/user/login", data, {withCredentials: true}).then(r => {
                dispatch(SessionAction.setSession(r));
                return r;
            });
        };
    };

    static loginVisitor = (): ThunkAction<any, any> => {
        return (dispatch, getState) => {
            if (!getState().session?.access_token) {
                return ajax.post("/user/login-visitor").then(r => {
                    dispatch(SessionAction.setSession(r));
                    return r;
                });
            }
        };
    };

    static logout = (): ThunkAction<Promise<Response>, any> => {
        return (dispatch): Promise<any> => {
            return ajax
                .post("/user/logout", {
                    close: false,
                    lights: false,
                })
                .then(r => {
                    // dispatch(SessionAction.setSession(null));
                    dispatch(SessionAction.resetAppState());
                    return r;
                });
        };
    };

    static resetPassword = (email: string, access_token: string, new_password: string, app_id?: string): ThunkAction<Promise<Response>, any> => {
        return (dispatch): Promise<any> => {

            return ajax.post("/user/reset-password", {
                email: email,
                password_hash: access_token,
                new_password: new_password,
                app_id: app_id,
            }).then(r => {
                // dispatch(SessionAction.setSession(r));
                return r;
            });
        };
    };

    static forgottenPassword = (email: string, app_url?: string): ThunkAction<Promise<Response>, any> => {
        return (dispatch): Promise<any> => {

            return ajax.post("/user/forgotten-password", {email: email, app_url: app_url}).then(r => {
                // dispatch(SessionAction.setSession(r));
                return r;
            });
        };
    };

    static fetchProfile = (): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
                .get("/user/profile", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    dispatch(SessionAction.setProfile(r));
                    return r;
                })
                .catch(e => {
                    return e;
                });
        };
    };


    static markNotificationRead = (notification: Notification): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
              .get("/user/markNotificationRead" + "?notification_id="+notification.id, {
                  withCredentials: true,
                  headers: {
                      Authorization: `Bearer ${session.access_token}`,
                  },
              })
              .then(r => {
                  dispatch(SessionAction.pushNotification(r.notification));
                  return r;
              });
        };
    };

    static markAllNotificationsRead = (): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
              .get("/user/markAllNotificationsRead", {
                  withCredentials: true,
                  headers: {
                      Authorization: `Bearer ${session.access_token}`,
                  },
              })
              .then(r => {
                  dispatch(SessionAction.setNotifications(r.notifications));
                  return r;
              });
        };
    };


    static fetchNotifications = () : ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
              .get("/user/notifications", {
                  withCredentials: true,
                  headers: {
                      Authorization: `Bearer ${session.access_token}`,
                  },
              })
              .then(r => {
                  dispatch(SessionAction.setNotifications(r.notifications));
                  return r;
              });
        };
    };

    static fetchMe = (): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
                .get("/user/me", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    dispatch(SessionAction.setProfile(r));
                    return r;
                });
        };
    };

    static fetchProfileTickets = (): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
                .get("/user/tickets", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    dispatch(SessionAction.setTickets(r.tickets));
                    return r;
                });
        };
    };

    static fetchResult = (guid: string): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
                .get("/stats/result/" + guid, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    dispatch(SessionAction.setResult(r));
                    return r;
                });
        };
    };

    static fetchResults = (
        hall_id: number,
        track_type: string | "inner" | "outer" = "inner",
        filter_timestamp: PeriodType = PeriodType.Month,
    ): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
                .get("/stats/results", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                    params: {
                        hall_id,
                        track_type,
                        filter_timestamp,
                    },
                })
                .then(r => {
                    console.log(r);
                    dispatch(SessionAction.setResults(r.results));
                    return r;
                });
        };
    };

    static fetchMyStats = (hall_id: number): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;
            return ajax
                .get("/stats/my", {
                    params: {
                        hall_id: hall_id,
                    },
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    console.log(r);
                    dispatch(SessionAction.setMyStats(r));
                    return r;
                });
        }
    }
    static fetchRanking = (filterPeriod: string, filterPersonType: string, trackLength: string, hall_id: number): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
                .get("/stats/ranking", {
                    params: {
                        hall_id: hall_id,
                        filter_timestamp: filterPeriod,
                        filter_persons_type: filterPersonType,
                        limit: 20,
                    },
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    console.log(r);
                    dispatch(SessionAction.setRanking(r.ranking));
                    return r;
                });
        };
    };

    static updateProfilePicture = (formData: FormData): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax.post("/user/update-profile-picture", formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            }).then(r => {
                dispatch(SessionAction.fetchMe());
                dispatch(SessionAction.fetchProfile());
                return r;
            });
        };
    };

    static updateProfile = (data): ThunkAction<Promise<UserResponse>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax.post("/user/update-profile", data, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            }).then(r => {
                dispatch(SessionAction.fetchMe()).then(r => {
                    consoleLog(r);
                });
                dispatch(SessionAction.fetchProfile()).then(r => {
                    consoleLog(r);
                });
                return r;
            });
        };
    };

    static pushNotification = (notification: Notification): ReducerSessionPushNotificationAction => {
        return createAction(SessionAction.PUSH_NOTIFICATION, notification);
    }

    static setNotifications = (notifications: Notification[]) => {
        return createAction(SessionAction.SET_NOTIFICATIONS, notifications);
    }

    static setSession = (session: SessionData | null) => {
        return createAction(SessionAction.SET_SESSION, session);
    };

    static setProfile = profile => {
        return createAction(SessionAction.SET_PROFILE, profile);
    };

    static setTickets = tickets => {
        return createAction(SessionAction.SET_TICKETS, tickets);
    };


    static setCheckin = driver => {
        return createAction(SessionAction.SET_CHECKIN, driver);
    };

    static setResult = result => {
        return createAction(SessionAction.SET_RESULT, result);
    };

    static setResults = results => {
        return createAction(SessionAction.SET_RESULTS, results);
    };

    static setRanking = ranking => {
        return createAction(SessionAction.SET_RANKING, ranking);
    };

    static setMyStats = myStats => {
        return createAction(SessionAction.SET_MYSTATS, myStats);
    };

    static setLanguage = language => {
        i18next.changeLanguage(language.code).then(r => {
            console.log(language);
        });
        return createAction(SessionAction.SET_LANGUAGE, language);
    };

    static resetAppState = () => {
        return createAction(SessionAction.RESET, null);
    };
}

export default SessionAction;
