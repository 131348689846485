import React, {Fragment, useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import "pages/Front/Login/Login.scss";
import T from "components/T";
import {useDispatch} from "useDispatch";
import {fetchAssertPayment} from "helpers/actions";
import {toast} from "react-toastify";
import "./Shop.scss";
import {getQueryParams} from "helpers/helpers";
import DashboardHeader from "../Dashboard/components/DashboardHeader";
import DashboardOneColumnLayout from "../components/DashboardOneColumnLayout";
import {useTranslation} from "react-i18next";
import {ShopItemsListSelector} from "./components/ShopItemsSelector";
import ShopCardItem from "./components/ShopCardItem";
import {ShopCartToggleIcon} from "./components/ShopCartToggleIcon";
import ShopTwoColumnLayout from "../components/ShopTwoColumnLayout";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {ShopCart} from "./components/ShopCart";
import {ArticleType} from "types/enums/ArticleType";
import {isMobileOnly} from "react-device-detect";
import {SubmitButton} from "components/Buttons/Buttons";
import {PaymentStatus} from "types/enums/PaymentStatus";
import {ShoppingBagIcon} from "@heroicons/react/24/solid";
import VoucherAction from "store/shop/VoucherAction";
import {Dialog, Transition} from "@headlessui/react";
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";
import {useShopItemsCartHook} from "utils/giftCardShopUtils";

interface Params {
    personType: string;
}

interface Props extends RouteComponentProps<Params> {
    param?: number;
    shopActive: boolean;
    setShopActive: any;
}

const Shop = (props: Props) => {
    const [card, setCard] = useState(props.match.params.personType || "adult");
    const [cartActive, setCartActive] = useState<boolean>(props.shopActive || false);
    const profile = useTypedSelector(state => state.sessionState.profile);
    const {createCart, getCart, resetCart} = useShopItemsCartHook();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const itemsCart = useTypedSelector(state => state.shopState.itemsCart);

    useEffect(() => {
        if (!itemsCart.shopArticleItemsCart) {
            createCart(profile);
        }
    }, []);

    useEffect(() => {
        if (itemsCart.shopArticleItemsCart) {
            getCart();
        }
    }, []);

    const setCartActiveHandler = active => {
        props.setShopActive(active);
        setCartActive(active);
    };

    useEffect(() => {
        const query = getQueryParams();

        if (query.assert) {
            dispatch(fetchAssertPayment(query.assert, query.token)).then(r => {
                if (r.assert) {
                }
                if (r.payment) {
                    if (r.payment.status === PaymentStatus.Started) {
                        toast.error(t("Payment error"));
                    } else if (r.payment.status === PaymentStatus.Finished) {
                        toast.success(t("Bought successfully."));
                        resetCart();
                        createCart(profile);
                        dispatch(VoucherAction.resetVouchers());
                        dispatch(VoucherAction.resetAppliedVouchers());
                    }
                }
            });
        }

        if (props.match.params.personType === "action-adult") {
            selectCard("actionAdult");
        } else if (props.match.params.personType === "action-child") {
            selectCard("actionChild");
        }
    }, []);

    useEffect(() => {
        setCartActiveHandler(props.shopActive);
    }, [props.shopActive]);

    const selectCard = type => {
        setCard(type);
        props.history.push("/shop/" + type);
    };
    return (
        <div className="Shop  flex relative z-2 justify-start w-full flex-grow flex-col items-stretch max-w-9xl mx-auto">
            <DashboardHeader
                subtitle={<T>Overview</T>}
                title={<T>Ticket</T>}
                titleSuffix={<T>Shop</T>}
                // rightPanelContent={
                //     <AreaSwitch initialActive={"adult"}
                //                 items={[
                //                     {name: "Adult", value: "adult"},
                //                     {name: "Children", value: "child"},
                //                 ]}
                //                 onClick={setActiveTab} />}
            />
            {!isMobileOnly && (
                <ShopTwoColumnLayout
                    contentLeft={<ShopItemsListSelector active={card} selectCard={selectCard} />}
                    contentRight={
                        <div className="flex flex-col justify-center items-center col-span-2 ">
                            <div className="w-full flex-grow relative z-50">
                                <div className="CardsList relative max-w-lg">
                                    <ShopCardItem
                                        active={card === "adult"}
                                        articleType={ArticleType.Online}
                                        personType={"adult"}
                                        subTitle={<T>races</T>}
                                        title={t("Adult")}
                                    />
                                    <ShopCardItem
                                        active={card === "child"}
                                        articleType={ArticleType.Online}
                                        personType={"child"}
                                        subTitle={<T>races</T>}
                                        title={t("Children")}
                                    />
                                    <ShopCardItem
                                        active={card === "actionAdult"}
                                        articleType={ArticleType.Action}
                                        personType={"adult"}
                                        subTitle={<T>Adult action ticket</T>}
                                        title={t("3 for 2")}
                                    />
                                    <ShopCardItem
                                        active={card === "actionChild"}
                                        articleType={ArticleType.Action}
                                        personType={"child"}
                                        subTitle={<T>Child action ticket</T>}
                                        title={t("3 for 2")}
                                    />
                                    <ShopCartToggleIcon setCartActive={() => setCartActiveHandler(true)} />
                                </div>
                            </div>
                            <Transition.Root as={Fragment} show={cartActive} unmount={false}>
                                <Dialog as="div" className={clsx("relative z-40 max-w-xs")} onClose={setCartActiveHandler}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transition-opacity ease-linear duration-300 transform-gpu"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity ease-linear duration-300 transform-gpu"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        unmount={false}
                                    >
                                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                                    </Transition.Child>
                                    <div
                                        className={clsx(!cartActive && "select-none pointer-events-none", "fixed right-0 inset-y-0 w-full max-w-lg flex z-40")}
                                    >
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transition ease-in-out duration-300 transform-gpu"
                                            enterFrom="translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transition ease-in-out duration-300 transform-gpu"
                                            leaveFrom="translate-x-0"
                                            leaveTo="translate-x-full"
                                            unmount={false}
                                        >
                                            <Dialog.Panel className="relative flex-1 flex flex-col w-[90%] pt-2 pb-2 bg-white border-r border-gray-600 shadow-lg">
                                                <ShopCart cartActive={cartActive} setCartActive={setCartActiveHandler} />
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </Dialog>
                            </Transition.Root>

                            {/*<SupportPopup />*/}
                        </div>
                    }
                >
                    {" "}
                </ShopTwoColumnLayout>
            )}
            {isMobileOnly && (
                <DashboardOneColumnLayout>
                    <div className={"ShopMobile w-full"}>
                        <div className={"relative flex-grow h-[6.5rem] sm:h-40 mb-2"}>
                            <div className={"absolute inset-0"}>
                                <ShopItemsListSelector active={card} selectCard={selectCard} />
                            </div>
                        </div>
                        <div className="CardsList relative max-w-sm lg:p-4 pb-12 sm:pb-24 flex flex-col items-center">
                            <ShopCardItem
                                active={card === "adult"}
                                articleType={ArticleType.Online}
                                personType={"adult"}
                                subTitle={<T>races</T>}
                                title={t("Adult")}
                            />
                            <ShopCardItem
                                active={card === "child"}
                                articleType={ArticleType.Online}
                                personType={"child"}
                                subTitle={<T>races</T>}
                                title={t("Children")}
                            />
                            <ShopCardItem
                                active={card === "actionAdult"}
                                articleType={ArticleType.Action}
                                personType={"adult"}
                                subTitle={<T>Adult action ticket</T>}
                                title={t("3 for 2")}
                            />
                            <ShopCardItem
                                active={card === "actionChild"}
                                articleType={ArticleType.Action}
                                personType={"child"}
                                subTitle={<T>Child action ticket</T>}
                                title={t("3 for 2")}
                            />
                            <div className={"flex flex-col w-full mx-auto mt-4 max-w-2xs"}>
                                <SubmitButton
                                    icon={<ShoppingBagIcon className={"w-6 h-6 mr-2"} />}
                                    text={t("Go to checkout")}
                                    type={"button"}
                                    onClick={() => setCartActiveHandler(!cartActive)}
                                />
                            </div>

                            <Transition.Root as={Fragment} show={cartActive} unmount={false}>
                                <Dialog as="div" className={clsx("relative z-40 md:hidden")} onClose={setCartActiveHandler}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transition-opacity ease-linear duration-300 transform-gpu"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity ease-linear duration-300 transform-gpu"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        unmount={false}
                                    >
                                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                                    </Transition.Child>
                                    <div className={clsx(!cartActive && "select-none pointer-events-none", "fixed inset-0 flex z-40")}>
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transition ease-in-out duration-300 transform-gpu"
                                            enterFrom="translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transition ease-in-out duration-300 transform-gpu"
                                            leaveFrom="translate-x-0"
                                            leaveTo="translate-x-full"
                                            unmount={false}
                                        >
                                            <Dialog.Panel className="relative flex-1 flex flex-col w-[90%] pt-2 pb-2 bg-white border-r border-gray-600 shadow-lg">
                                                <ShopCart cartActive={cartActive} setCartActive={setCartActiveHandler} />
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                        </div>
                    </div>
                </DashboardOneColumnLayout>
            )}
            <div
                className="c-childinfo__popup"
                style={{
                    display: "none",
                    opacity: 0,
                    transform: "translate3d(0, 150%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
            >
                <div className="c-card-wrapper-large">
                    <div className="u-flex-vertical-center">
                        <h1 className="t-p0">
                            <T>Children races</T>
                        </h1>
                        <div className="u-marg-t-sm u-align-center">
                            <p className="t-t3">Ipusum here</p>
                        </div>
                        <div className="u-flex u-h100 u-marg-t-md">
                            <div className="c-btn-3" data-w-id="6589a1ed-c832-a0ca-37fb-c2ebd4eae511">
                                <div className="t-p1">
                                    <T>No</T>
                                </div>
                                <div className="t-t3">
                                    <T>thanks</T>
                                </div>
                            </div>
                            <div className="c-parser" />
                            <div className="c-btn-3" data-w-id="6589a1ed-c832-a0ca-37fb-c2ebd4eae517">
                                <div className="t-p1">
                                    <T>Yes</T>
                                </div>
                                <div className="t-t3">
                                    <T>please</T>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Shop);
