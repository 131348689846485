export enum HallCode {
    Budapest = "bd",
    BudapestKoki = "bd2",
    Spreitenbach = "sp",
    Trencin = "tn",
    Winterthur = "wt",
    Zwolle = "zw",
    Phpcode = "php",
    Dev = "dev",
    Corner = "corner"
}