import {PackageItem, PackageItemItemType} from "types/entities/PackageItem";
import {Package} from "types/entities/Package";
import {PackageItemPersonType} from "types/enums/PackageItemPersonType";
import {BookingFilter} from "types/app";
import {filterPackageItemsByType, subtractDaysFromDates, uniqueFilter} from "utils/utility";
import {PersonType} from "types/enums/PersonType";
import {PackageCategory} from "types/enums/PackageCategory";
import Dates from "utils/Dates";
import {PackagesCart} from "types/entities/PackagesCart";
import {voucherTypeByArticle} from "./voucherUtils";
import {VoucherDiscountType, VoucherItem} from "../types/entities/VoucherItem";
import {HallCode} from "types/enums/HallCode";
import {GroupTypeId} from "types/enums/GroupTypeId";
import {IS_PROD} from "../helpers/config";

/* Find PackageItems by provided PackageItemPersonType
 * @param packageItem[], PackageItemPersonType
 */
export const findPackageItemByPersonType = (packageItems: PackageItem[], personType: PackageItemPersonType): PackageItem | undefined => {
    return packageItems.find(packageItem => {
        if (typeof packageItem.person_type === "undefined" || packageItem.type === PackageItemItemType.Upsell) return null;
        if (packageItem.is_active === 1) {
            return packageItem.person_type === personType;
        }
        return null;
    });
};

export const findPackageItemByPersonTypeAndCount = (packageItems: PackageItem[], personType: PackageItemPersonType, count: number): PackageItem => {
    const packageItemsByType = packageItems.filter(packageItem => {
        if (typeof packageItem.person_type === "undefined") return null;
        if (packageItem.type !== PackageItemItemType.PackageItem) return null;
        return packageItem.person_type === personType;
    });
    const packageItemByCount = packageItemsByType.find(packageItem => {
        if (packageItem.minimum_persons_count > count) return false;
        return packageItem.maximum_persons_count >= count;
    });
    return <PackageItem>packageItemByCount;
};

/* Return only the items of type PackageItemItemType.PackageItem  */
export const getBookingPackageItems = (Package: Package): PackageItem[] => {
    return Package.packageItems.filter(packageItem => packageItem.type === PackageItemItemType.PackageItem);
};

/*Compare bookingFilter to selected package requirements */
export const filterFitsWithPackageRequirements = (Package: Package | null, bookingFilter: BookingFilter): boolean => {
    let disabled = true;
    let packages: PackageItem[] | false;
    if (!Package) {
        return true;
    }
    if (!getBookingPackageItems(Package)) {
        return true;
    } else {
        packages = getBookingPackageItems(Package);
    }

    let hasEnoughAdults = false;
    let hasTooMuchAdults = false;

    let hasEnoughChildren = false;
    let hasTooMuchChildren = false;

    let hasEnoughPersonsCount = false;
    let hasTooMuchPersonsCount = false;
    let isBookableByHour = false;

    const adultPackageItem = findPackageItemByPersonTypeAndCount(packages, PackageItemPersonType.Adult, bookingFilter.adults);
    const childPackageItem = findPackageItemByPersonTypeAndCount(packages, PackageItemPersonType.Child, bookingFilter.children);
    if (adultPackageItem) {
        //first check if there are enough adults, independent from Package type
        hasEnoughAdults = adultPackageItem.minimum_persons_count <= +bookingFilter.adults;
        hasTooMuchAdults = adultPackageItem.maximum_persons_count < +bookingFilter.adults;
    }

    if (childPackageItem) {
        /*first check if there are enough children, independent from Package type*/
        hasEnoughChildren = childPackageItem.minimum_persons_count <= +bookingFilter.children;
        hasTooMuchChildren = childPackageItem.maximum_persons_count < +bookingFilter.children;
    }

    if (adultPackageItem && childPackageItem) {
        /* both packageItems exists, bookingFilters should be counted together */
        if (Package?.min_drivers_count <= +bookingFilter.adults + bookingFilter.children) disabled = false;
    }

    hasEnoughPersonsCount = Package?.min_drivers_count <= +bookingFilter.adults + bookingFilter.children;
    hasTooMuchPersonsCount = Package?.max_drivers_count < +bookingFilter.adults + bookingFilter.children;
    isBookableByHour = !!Package?.extra.bookableByHour;

    const minDaysFromNow = Package.extra.minDaysFromNow;
    if (minDaysFromNow && minDaysFromNow > 0) {
        const date1 = subtractDaysFromDates(new Date(bookingFilter.date), minDaysFromNow);
        const date2 = new Date();
        date2.setHours(0);
        date2.setMinutes(0);
        date2.setSeconds(0);
        disabled = date1 < date2;
    }

    /*if packageItems has only one of personType, we take only that kind in consideration */
    if (adultPackageItem && hasEnoughAdults && childPackageItem && hasEnoughChildren) {
        disabled = false;
    }

    if (adultPackageItem && childPackageItem && hasEnoughPersonsCount && Package?.min_drivers_count > 0) {
        disabled = false;
    }

    if (hasTooMuchAdults || hasTooMuchChildren) {
        disabled = true;
    }

    disabled = !hasEnoughPersonsCount;

    if (hasTooMuchPersonsCount) {
        disabled = true;
    }

    if (isBookableByHour && bookingFilter.hours_count == 0) {
        disabled = true;
    }
    // console.log(hasEnoughChildren);
    // console.log(hasTooMuchAdults);
    // console.log(hasTooMuchChildren);
    // console.log("------------")
    // console.log(hasEnoughPersonsCount);
    // console.log(hasTooMuchPersonsCount);
    //
    // console.log(adultPackageItem);
    // console.log(childPackageItem);
    // console.log("disabled", disabled);
    return disabled;
};

/* filter packages based on minimum and maximum drivers count compared to bookingFilter */
export const packagesFilter = (packages: Package[], bookingFilter: BookingFilter) => {
    const {adults, children, groupType} = bookingFilter;
    const filteredPackages = Object.values(packages);
    if (filteredPackages.length === 0) return [];

    const filteredCounts = filteredPackages.filter(packageRow => {
        if (adults + children === 0) {
            return true;
        }
        return packageRow.min_drivers_count <= adults + children && packageRow.max_drivers_count >= adults + children;
    });

    return filteredCounts.filter(packageRow => {
        if (packageRow.category === PackageCategory.Profile) {
            return false;
        }
        if (groupType.length === 0) {
            return true;
        }
        if (groupType.length > 0 && packageRow.extra.groupType && packageRow.extra.groupType.length == 0) {
            return false;
        }
        if (packageRow.extra.groupType) {
            if (packageRow.extra.groupType.length == 0) {
                return true;
            }
            if ("groupType" in packageRow.extra === false) {
                return true;
            }
        }
        if (packageRow.extra.groupType) {
            if (typeof groupType == "object") {
                return (
                    groupType.filter(gT => {
                        if (packageRow.extra.groupType) {
                            return packageRow.extra.groupType.includes(+gT);
                        }
                        return false;
                    }).length > 0
                );
            }
            if (typeof groupType == "string") {
                return packageRow.extra.groupType.filter(gt => gt === +groupType).length > 0;
            }
        }
    });
};

/* filter unique activities from package */
export const filteredActivities = (packageDetail): number[] => {
    return uniqueFilter(packageDetail.packageItems.map(packageItem => packageItem.activity_id)); //skip null activities
};

/* filter unique packageItems based on activity */
export const uniquePackageWithActivityItem = (packageDetail, withActivity?: boolean, personType: PersonType = PersonType.Adult): PackageItem[] => {
    const filteredActivitiesArray = filteredActivities(packageDetail).filter(f => (withActivity ? f : f === null));
    const filteredPackages = filteredActivitiesArray.map(activityNumber => {
        const filteredPackageItems = filterPackageItemsByType(packageDetail, personType);
        const filteredPackageItemIndex = filteredPackageItems.findIndex(packageItem => packageItem.activity_id === activityNumber);
        return filteredPackageItems[filteredPackageItemIndex];
    });
    return filteredPackages.filter(packageItem => packageItem); //ignore undefined and null
};

/***
 * Return upsell with extra_heat field
 * @param packageDetail
 * @param personType
 */
export const getExtraHeatPackageItem = (packageDetail: Package, personType: PackageItemPersonType = PackageItemPersonType.Adult): PackageItem | undefined => {
    return packageDetail.packageItems
        .filter(packageItem => packageItem.person_type === personType)
        .find(packageItem => {
            if (packageItem.type === PackageItemItemType.Upsell) {
                return packageItem.extra.extra_heat;
            }
            return false;
        });
};

export const minimumPackagePrice = (packageDetail: Package, personType: PackageItemPersonType = PackageItemPersonType.Adult): PackageItem => {
    const filteredPackageByPersonType = packageDetail.packageItems
        .filter(packageItem => packageItem.person_type === personType)
        .filter(packageItem => packageItem.type === PackageItemItemType.PackageItem);
    const minimum = Math.min(...filteredPackageByPersonType.map(packageItem => packageItem.price));
    return <PackageItem>filteredPackageByPersonType.find(item => item.price === minimum);
};

export const isWeekdayDiscountedPackageItem = (weekDay: number, packageItem: PackageItem): boolean => {
    if (!packageItem.extra.includeWeekDays) {
        return false;
    }
    const days = packageItem.extra.includeWeekDays.filter(includeWeekDay => includeWeekDay === weekDay);
    return days.length > 0;
};

export const packageHasUpsellItems = (Package: Package): boolean => {
    return Package.packageItems.filter((packageItem: PackageItem): boolean => packageItem.type === PackageItemItemType.Upsell).length > 0;
};

export const packageMinimalDate = (Package: Package): Date => {
    const today = new Date();
    if (Package.extra.minDaysFromNow) {
        return new Date(Dates.addDays(today.toString(), Package.extra.minDaysFromNow).toString());
    }
    return today;
};

/**
 * Stable function that returns weekday numbers from filterByWeekDay
 *
 * @param Package
 * @param month monthIndex 0-11
 */
const getWeekDaysFromPackageExtra = (Package: Package, month: number) => {
    const weekDays: number[] = Package.extra.filterByWeekDay
        ? Package.extra.filterByWeekDay.map(packageExtraFilter => {
              const weekDay = packageExtraFilter.weekDay;
              if (weekDay === 7) {
                  return 0;
              }
              return weekDay;
          })
        : [];

    return weekDays;
};

/**
 * Stable function creates arrays of days that are included
 * @param date
 * @param month
 * @param weekDays
 */
const createMonthDateArray = (date, month, weekDays) => {
    const days: Date[] = [];
    while (date.getMonth() === month) {
        //repeat for the whole month from 1st date until end of month
        if (weekDays.includes(date.getDay())) {
            days.push(new Date(date));
        }
        date.setDate(date.getDate() + 1);
    }
    return days;
};

const generateConsecutiveMonths = (dateEntry: Date, monthIndex: number, weekDays: number[]) => {
    // Validate the input parameters
    if (!(dateEntry instanceof Date)) {
        throw new Error("dateEntry should be a valid Date object.");
    }
    if (typeof monthIndex !== "number" || monthIndex < 0 || monthIndex > 11) {
        throw new Error("monthIndex should be an integer between 0 and 11.");
    }

    // Create an array to store the first days of consecutive months
    const firstDays: Date[] = [];

    // Clone the dateEntry to avoid modifying the original object
    const currentDate = new Date(dateEntry);

    // Set the month of the currentDate to the specified monthIndex
    currentDate.setMonth(monthIndex);

    // Use a while loop to generate first days of 12 consecutive months
    let monthCount = 0;
    while (monthCount < 12) {
        // Push the first day of the current month to the array
        const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        firstDays.push(...createMonthDateArray(newDate, newDate.getMonth(), weekDays));

        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);

        monthCount++;
    }
    return firstDays;
};

export const packageExcludeDates = (Package: Package, month: number, type: "exclude" | "include" = "exclude"): Date[] => {
    const weekDays = getWeekDaysFromPackageExtra(Package, month);
    let days: Date[] = [];
    const year = new Date().getFullYear();
    const date = new Date(year, month, 1);
    const allValid = [0, 1, 2, 3, 4, 5, 6];
    const filterWeekDays = allValid.filter(num => {
        if (weekDays.length == 0) {
            return type === "include";
        }
        return type === "include" ? weekDays.includes(num) : !weekDays.includes(num);
    });

    if (filterWeekDays.length > 0) {
        days = generateConsecutiveMonths(date, month, filterWeekDays);
    }
    return days;
};

export const getPackageRespectsTimeslotSize = (Package: Package): boolean => {
    const category = Package.category;
    switch (category) {
        case PackageCategory.Event:
            return false;
        case PackageCategory.Main:
            return false;
        case PackageCategory.General:
            return true;
        case PackageCategory.Profile:
            return true;
        case PackageCategory.Season:
            return true;
    }

    return true;
};

export const getPackageTimeslotsCount = (Package: Package, personType: PackageItemPersonType = PackageItemPersonType.Adult): number => {
    const packageCount = Package.packageItems
        .filter((packageItem: PackageItem) => packageItem.person_type === personType)
        .reduce((prev: number, cur: PackageItem) => {
            const packageItemTimeslotsCount = cur.article.race_count_total;
            if (!prev) {
                return packageItemTimeslotsCount;
            }
            return prev + packageItemTimeslotsCount;
        }, 0);
    return packageCount;
};

export const isGiftCard = (Package: Package) => Package.category === PackageCategory.Giftcard;
export const isEvent = (Package: Package) => Package.category === PackageCategory.Event;
export const isGroupTypeChildren = (Package: Package) => Package.extra.groupType && Package.extra.groupType.includes(GroupTypeId.Children);

export const packagesCartContainsUpsells = (packagesCart: PackagesCart, upsellOptionType?: boolean): boolean => {
    if (
        typeof packagesCart.packagesCartItems?.filter(packagesCartItem => packagesCartItem.packageItem?.type === PackageItemItemType.Upsell).length === "number"
    ) {
        if (typeof upsellOptionType !== "undefined") {
            return (
                packagesCart.packagesCartItems
                    ?.filter(packagesCartItem => packagesCartItem.packageItem?.type === PackageItemItemType.Upsell)
                    .filter(packagesCartItem => {
                        if (typeof packagesCartItem !== "undefined" && typeof packagesCartItem.packageItem !== "undefined") {
                            // @ts-ignore
                            return packagesCartItem?.packageItem?.extra.upsell_option >= 0;
                        }
                    }).length > 0
            );
        }

        return packagesCart.packagesCartItems?.filter(packagesCartItem => packagesCartItem.packageItem?.type === PackageItemItemType.Upsell).length > 0;
    }
    return false;
};

export const getPersonsTypeFraction = (totalPersons, limit) => (totalPersons <= limit ? totalPersons / limit : 1);

/**
 *
 * @param packageRow
 * @param eligibleVoucher
 * @return number
 */
export const voucherDiscount = (packageRow: Package, eligibleVoucher: VoucherItem): number => {
    let discountedPrice: number = packageRow.price;
    const packageArticle = packageRow.packageItems.map(packageItem => packageItem.article);
    const voucherDiscountType = voucherTypeByArticle(eligibleVoucher).type;

    if (!IS_PROD) {
        console.log(voucherDiscountType);
    }

    switch (voucherDiscountType) {
        case VoucherDiscountType.Percent:
            return (discountedPrice = packageRow.price * ((100 - voucherTypeByArticle(eligibleVoucher).value) / 100));
        case VoucherDiscountType.Race:
            const voucherArticleMatchRace = packageArticle.find(article => {
                return article.race_count_total == eligibleVoucher.article.race_count_total;
            });
            if (!IS_PROD) {
                console.log(voucherArticleMatchRace);
            }
            return (discountedPrice = voucherArticleMatchRace ? packageRow.price - voucherArticleMatchRace.article_value : packageRow.price);
        case VoucherDiscountType.Activity:
            const voucherArticleMatchActivity = packageArticle.find(article => {
                // @ts-ignore // already matched with if(eligibleVoucher)
                return article.article_id === props.eligibleVoucher.article.article_id;
            });
            return (discountedPrice = voucherArticleMatchActivity ? packageRow.price - voucherArticleMatchActivity.article_value : packageRow.price);
        default:
            return discountedPrice;
    }
};

export const getModuloMinutesByPackageAndHall = (Package: Package, hallCode: HallCode) =>
    isGroupTypeChildren(Package) ? 60 : hallCode === HallCode.Spreitenbach ? (Package.rewrite_url === "exclusive" ? 30 : 60) : 60;
