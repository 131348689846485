import ajax from "helpers/ajax";
import {createAction} from "helpers/createAction";
import {DEFAULT_HALL_ID} from "helpers/config";

class ArticlesAction {
    static SET_ARTICLES = "ArticlesAction.SetArticles";

    static fetchArticles = () => {
        return dispatch => {
            return ajax.get("/articles/list").then(r => {
                dispatch(ArticlesAction.setArticles(r.articles));
            });
        };
    };


    static fetchArticlesWithHall = () => {
        return dispatch => {
            return ajax.get("/articles/list?hall_id=" + DEFAULT_HALL_ID).then(r => {
                dispatch(ArticlesAction.setArticles(r.articles));
            });
        };
    };

    static setArticles = articles => {
        return createAction(ArticlesAction.SET_ARTICLES, articles);
    };
}

export default ArticlesAction;
