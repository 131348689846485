import {AdultOrChild, BookingFilter} from "../types/app";
import {ArticleType} from "types/enums/ArticleType";
import {Article} from "types/entities";
import {
    CURRENCY,
    DEFAULT_LANGUAGE,
    IS_PROD,
    IS_UPSELL_ALLOWED,
    LOCALE,
    PAYMENT_TEST,
    TIMER_COUNT,
    Translations,
} from "helpers/config";
import {ArticlesReducerState} from "store/articles/ArticlesReducer";
import store from "helpers/store";
import {PackageItemItemType} from "types/enums/PackageItemItemType";
import {PackageItemPersonType} from "types/enums/PackageItemPersonType";
import {Package} from "types/entities/Package";
import {PersonType} from "types/enums/PersonType";
import {PackageItem} from "types/entities/PackageItem";
import {PackagesCartItem} from "types/entities/PackagesCartItem";
import {DependencyList, useEffect, useRef} from "react";
import {PersonsType} from "types/enums/PersonsType";
import {PackagesCart} from "types/entities/PackagesCart";
import {Profile} from "types/entities/Profile";
import {PackageUpsell} from "store/booking/BookingAction";
import {BookingNavigationStep, BookingReducerState} from "store/booking/BookingReducer";
import {packageHasUpsellItems, uniquePackageWithActivityItem} from "utils/packageUtils";
import {toast} from "react-toastify";
import {PriceOffer} from "types/entities/PriceOffer";
import { Driver } from "types/entities/Driver";
import i18n from 'i18next';
import ajax from "../helpers/ajax";

export const driverPlaceTextSuffix = (i: number) => {
    switch (i) {
        case 1:
            return i18n.t("st");
        case 2:
            return i18n.t("nd");
        case 3:
            return i18n.t("rd");
        default:
            return i18n.t("th");
    }
};

export const driverName = (driver: Driver) => {
    if(typeof driver.last_name === "undefined") {
        return driver.username || driver.name;
    }
    return driver.username || driver.name + " " + driver.last_name;
};

export const getTodayDate = (): number => {
    return Math.floor(Date.now() / 1000);
};

export const lapTime = (laptime: number): string => {
    return (laptime / 100).toFixed(2) + "s";
};

export const percentageTaxFormat = value => {
    return new Intl.NumberFormat(LOCALE, {
        style: "percent",
        currency: CURRENCY,
        minimumSignificantDigits: 2,
    }).format(value / 100);
};

export const currencyNumberFormat = value => {
    return new Intl.NumberFormat(LOCALE, {
        style: "currency",
        currency: CURRENCY,
    minimumFractionDigits: 2,
    }).format(value / 100);
};

/** this function is deprecated */
export const formatPriceCurrency = (price: number, currency: string, precision: number = 2): string => {
    return (price || 0).toFixed(precision) + " " + currency;
};

export const getArticle = (articlesState: ArticlesReducerState, articleType: ArticleType, personType: AdultOrChild, count: number) => {
    if (PAYMENT_TEST) {
        return articlesState.all?.find(article => article.article_id === 3000);
    }

    if (articleType === ArticleType.Action) {
        return articlesState[articleType][personType].find((article, index) => index === 0);
    } else {
        return articlesState[articleType][personType].find(article => article.race_count_total === count);
    }
};

export const getArticlePrices = (articlesState: ArticlesReducerState, articleType: ArticleType, personType: AdultOrChild, count: number): PriceOffer => {
    const baseArticle: Article | undefined = getArticle(articlesState, ArticleType.Online, personType, 1);
    const article = getArticle(articlesState, articleType, personType, count);

    if (!article || !baseArticle) {
        return {
            price: 0,
            pricePerRace: 0,
            savings: 0,
        };
    }

    if (articleType === ArticleType.Action) {
        return {
            price: article?.article_value,
            pricePerRace: article?.article_value / article?.race_count_total,
            savings: baseArticle?.article_value * article?.race_count_total - article?.article_value,
        };
    } else {
        return {
            price: article.article_value,
            pricePerRace: article.article_value / article.race_count_total,
            savings: baseArticle.article_value * count - article.article_value,
        };
    }
};

export const getStatsNumberTextByType = (type: string | undefined, number: number) => {
    switch (type) {
        case "position":
            return number + " " + driverPlaceTextSuffix(number);
        case "time":
            return number + "s";
        case "number":
            return number;
        default:
            return number;
    }
};

export const getArticleById = (id: number) => {
    return store.getState().articlesState.all?.find((article: Article) => article.id === id);
};

/** this function is deprecated */
export const translatePriceToFloat = (price: number, precision?: number) => {
    return formatPriceCurrency(price / 100, CURRENCY, precision);
};

export function getEnumKeyByEnumValue<T extends {[index: string]: string}>(myEnum: T, enumValue: string): keyof T | null {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
}

export const serializeFormData = (data: any, formData?: FormData, previousKey?: any) => {
    if (!formData) {
        formData = new FormData();
    }

    Object.keys(data).map(key => {
        const value = data[key];

        // example: object in an array (flags: Flag[])
        if (!(value instanceof File) && !(value instanceof FileList) && value instanceof Object && !Array.isArray(value)) {
            return serializeFormData(value, formData, key);
        }

        if (previousKey) {
            key = `${previousKey}[${key}]`;
        }

        if (value instanceof FileList) {
            const files = [...data[key]];
            files.map((file, index) => {
                // @ts-ignore We for sure know that formData IS set.
                formData.append(`${key}[${index}]`, file);
            });
        } else if (Array.isArray(value)) {
            value.map((val, index) => {
                serializeFormData(val, formData, `${key}[${index}]`);
            });
        } else {
            // @ts-ignore We for sure know that formData IS set.
            formData.append(key, value);
        }
    });
    return formData;
};

export const filterPackageItemsByType = (Package: Package, person_type: PackageItemPersonType | PersonType): PackageItem[] => {
    return Package.packageItems?.filter(packageItem => packageItem.person_type === person_type).filter(
        packageItem => packageItem.type === PackageItemItemType.PackageItem
    );
    // .sort((a, b) => {
    //     if(a.position === null && b.position === null) return 0;
    //     if(a.position === null ) return b.position as number;
    //     if(b.position === null ) return a.position as number;
    //     return (
    //         a.position < b.position ? -1 : 1
    //     );
    // });
};

export const personTypeLabel = (person_type: PackageItemPersonType | null) => {
    switch (person_type) {
        case PackageItemPersonType.Adult:
            return i18n.t("Adult person");
        case PackageItemPersonType.Child:
            return i18n.t("Child person");
        case PackageItemPersonType.Event:
            return i18n.t("Event person");
        case PackageItemPersonType.Family:
            return i18n.t("Family person");
        default:
            return i18n.t("Any person");
    }
};

export const translate = (translation: Translations, defaultLanguage: string = "") => {
    const language = store.getState().hallState.hall?.language.code;
    const userLanguage = store.getState().sessionState.language?.code;
    if (!defaultLanguage) {
        defaultLanguage = DEFAULT_LANGUAGE;
    }

    // @ts-ignore
    return translation[userLanguage] || translation[language] || translation[defaultLanguage] || translation["en"] || translation || "not found";
};

export const truncateTextToLength = (str: string, length?: number) => {
    if (!str) {
        return "";
    }
    if (!length) {
        length = 300;
    }

    const dots = str.length > length ? "..." : "";
    if(!str.length) return "";
    return str.substring(0, length) + dots;
};

const DurationReducer = (accumulator, cur: PackageItem) => {
    let accDuration = 0;
    const article = cur.article;
    if (article && article.race_count_total && typeof article !== undefined) {
        accDuration = article.race_count_total > 1 ? cur.duration * article.race_count_total : cur.duration;
    }

    if (isNaN(accumulator)) {
        return accDuration;
    }
    return accumulator + accDuration;
};

export const packageDuration = (packageRow: Package) => {
    const durationByPackageItems =  uniquePackageWithActivityItem(packageRow, true).reduce(DurationReducer, 0) + uniquePackageWithActivityItem(packageRow, false).reduce(DurationReducer, 0);
    const durationByPackage = packageRow.duration;
    return durationByPackageItems > durationByPackage ? durationByPackageItems : durationByPackage;
};

export const getBreakClaimText = (index: number, type: string) => {
    const gameBreakClaims = [
        "View and compare the results",
    ];

    const activityBreakClaims = [
        "Time to take a quick brake and move on to next activity",
        "You need to walk to another activity and get ready",
        "You're getting ready for your next activity",
        "You need to walk to another activity and get ready",
        "Time to take a quick brake and move on to next activity",
    ];

    return type === "activity" ? i18n.t(activityBreakClaims[index]).toString() : i18n.t(gameBreakClaims[index]).toString();
};

export const getPackageItemFromPackageCartItem = (packageCartItem: PackagesCartItem, package_: Package) => {
    if (package_.packageItems) {
        return package_.packageItems.find(packageItem => packageItem.id === packageCartItem.package_item_id);
    }
};

export const padMinutes = (minutes: number) => {
    return ("00" + minutes).slice(-2);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const useDidUpdateEffect = (fn: Function, inputs: DependencyList) => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        } else {
            didMountRef.current = true;
        }
    }, inputs);
};

export const consoleDebugger = () => {
    debugger; // eslint-disable-line no-debugger
};

export const consoleLog = (...params) => {
    if (IS_PROD) {
        return;
    }
    console.log(...params);
};


export const uniqueFilter = array => array.filter((v, i, a) => a.indexOf(v) === i);

export function redirectToPackageBooking(history, Package: Package, filter: BookingFilter ) {
    const upsellItems = packageHasUpsellItems(Package);
    if(Package.use_timeslots === 0) {
        history.replace("/booking/customer/?" + appendQueryParams(filter));
        return;
    }
    if (IS_UPSELL_ALLOWED && upsellItems) {
        history.replace("/booking/upsell/" + Package.id + "?" + appendQueryParams(filter));
        return;
    } else {
        history.replace("/booking/book/" + Package.id  + "?" + appendQueryParams(filter));
        return;
    }
}
export function redirectToReservationInquired(history, packagesCart: PackagesCart) {
    // /booking/inquiry/:cart_id/:cart_token
    history.replace("/booking/inquiry/" + packagesCart.id + "/" + packagesCart.token);
}

export const isAdult = (personsType: PersonType | PersonsType | string) => {
    return personsType === PersonType.Adult || personsType === PersonsType.Adult || personsType === "adult";
};

export const isChild = (personsType: PersonType | PersonsType | string) => {
    return personsType === PersonType.Child || personsType === PersonsType.Child || personsType === "child";
};

export const isFamily = (personsType: PersonsType | string) => {
    return personsType === PersonsType.Family || personsType === "family";
};

export const isUnknown = (personsType: PersonsType | string) => {
    return personsType === PersonsType.Unknown || personsType === "unknown";
};

export const validateByPersonType = (personsType: PersonsType | string): boolean => {
    switch (personsType) {
        case PersonsType.Adult:
            return isAdult(personsType);
        case PersonsType.Child:
            return isChild(personsType);
        case PersonsType.Family:
            return isFamily(personsType);

        default:
            return isUnknown(personsType);
    }
};

export const filterPersonsType = (filter: BookingFilter) => {
    if (filter.children > 0 && filter.adults > 0) {
        return PersonsType.Family;
    }
    if (filter.adults > 0) {
        return PersonsType.Adult;
    }
    if (filter.children > 0) {
        return PersonsType.Child;
    }
    return PersonsType.Unknown;
};

export const packagesCartTimeout = (packagesCart: PackagesCart) => {
    const timeNow = new Date().getTime();
    const packagesCartTime = new Date(packagesCart.updated_at).getTime();

    return packagesCartTime + TIMER_COUNT <= timeNow;
};

export const getCurrentDriver = (profile: Profile) => {
    return {
        identifier: +profile.id,
        name: profile.name,
        profile_picture_url: profile?.profile_picture_url || null,
        experience: profile.experience,
        person_type: profile.person_type,
        selected: false,
        races_count: profile.ticket_races_left,
        races_assigned_count: profile.ticket_races_left,
    };
};

export const getPersonTypeColorClass = (personType: PersonType) => {
    switch (personType) {
        case PersonType.Adult:
            return "text-blue-800 bg-blue-400";
        case PersonType.Child:
            return "text-green-800 bg-green-400";
        default:
            return "text-indigo-800 bg-indigo-400";
    }
};

export const getReservationTicketColorClass = (personType: PersonType, type: ArticleType) => {
    switch (personType) {
        case PersonType.Adult:
            if(type == ArticleType.Action) {
                return "text-indigo-800 bg-indigo-400";
            }
            return "text-blue-800 bg-blue-400";

        case PersonType.Child:
            return "text-green-800 bg-green-400";
            if(type == ArticleType.Action) {
                return "text-blue-800 bg-blue-400";
            }
            return "text-blue-800 bg-blue-400";
        default:
            return "text-indigo-800 bg-indigo-400";
    }
};

export const appendQueryParams = (filter) => {
    return new URLSearchParams(filter as any).toString();
}

export const getPackageItemsWithoutGroupOptions = (Package: Package): PackageItem[] => {
    return Package.packageItems
        .filter(packageItem => packageItem.type === PackageItemItemType.Upsell)
        .filter(packageItem => packageItem.extra?.group === null);
}

export const getPackageItemsWithGroupOptions = (Package: Package): PackageItem[] => {
    return Package.packageItems
        .filter(packageItem => packageItem.type === PackageItemItemType.Upsell)
        .filter(packageItem => packageItem.extra.group !== null);
}

export const getSelectedUpsell = (upsells: PackageUpsell[], packageItem) => {
  return upsells.find(upsell => upsell.packageItem.id === packageItem.id)
}

export const getNewStep = (bookingState: BookingReducerState, stepId: number): BookingNavigationStep => {
    const steps = bookingState.timeslotsAllowed
        ? bookingState.navigation.timeslotsAllowedSteps
        : bookingState.navigation.timeslotsNotAllowedSteps;

    return steps.find(step => step.id === stepId) || bookingState.navigation.currentStep;
}
export const sortPackageItemsByPosition = (packageItems: PackageItem[]) => {
    return packageItems.sort((a, b) => {
        if(a.position === null || b.position === null) return 1;
        return a.position > b.position ? 1 : -1;
    });
}

export const packageItemUpsellPersonType = (bookingFilter, personType: PackageItemPersonType | null) => {
    if(!personType) return true;
    if(bookingFilter.adults > 0 && personType === PackageItemPersonType.Adult) return true;
    if(bookingFilter.children > 0 && personType === PackageItemPersonType.Child) return true;
}

export const copyToClipboard = async (text?: string): Promise<boolean> => {
    try {
        const toCopy = text || location.href;
        await navigator.clipboard.writeText(toCopy);
        toast.success(i18n.t("Copied to clipboard") +  ": " + toCopy);
        return true;
    } catch (err) {
        console.error("Failed to copy: ", err);
        return false;
    }
};

export const truncateString = (text: string, length: number, symbol?: string) => {
    if(typeof symbol === "undefined") {
        symbol = "."
    }
    if(text.length > length) {
        return text.slice(0, length - 1) + "" + symbol;
    }
    return text;
}

export const subtractDaysFromDates = (dateFrom: Date, days: number):Date => {
    return new Date(dateFrom.setDate(dateFrom.getDate() - days))
}

export const range = (size, startAt = 0) => {
    // @ts-ignore
    return [...Array(size).keys()].map(i => i + startAt);
}

export const phoneValidation = (phone: any) => {
    const phoneTrimmed = phone.replace(/\s/g, "");
    const regex = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{3,6})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    return regex.test(phoneTrimmed);
};


export const checkAddress = (addressData: {city: string; street_address: string; postcode: string}) => {
    const url = "https://service.post.ch/zopa/app/api/addresschecker/v1/houses/search?noCache=";
    const timestamp = new Date().getTime();

    let numb = addressData?.street_address?.match(/\d/g);
    const houseNumber = numb ? numb.join("") : null;
    const {city, street_address, postcode} = addressData;
    const params = {
        city: city,
        street: street_address,
        limit: 60,
        houseNbr: houseNumber,
        noCache: timestamp,
    };
    const result = ajax.get(url, {params: params});
    console.log(result);
};