import {ShopArticleItem} from "types/entities/ShopArticleItem";
import {currencyNumberFormat} from "utils/utility";
import {Article} from "types/entities";
import {ArticleType, PersonType} from "types/enums";
import ShopAction from "store/shop/ShopAction";
import {useDispatch} from "useDispatch";
import {ThunkAction} from "redux-thunk";
import {ArticleItem} from "types/entities/ArticleItem";
import {useTypedSelector} from "helpers/reducers";

export const shopArticlePrice = (shopArticleItem: ShopArticleItem) => {
    return currencyNumberFormat(shopArticleItem.article.article_value / shopArticleItem.quantity);
};

export const articlePrice = (count, personType, articles): string => {
    const article = findArticle(count, personType, articles);

    if (!article) {
        return "";
    }
    if (count === 0) {
        count = 1;
    }
    return currencyNumberFormat(article.article_value / count);
};

export const filterArticlesByType = (articles: Article[], articleType: ArticleType, activity_id: number) => {
    return articles.filter(article => {
        return article.type === articleType;
    }).filter(article => article.activity_id === activity_id);
};

export const findArticle = (count, personType, articles): Article | null => {
    const article = articles.find(article => {
        if (count === 0) {
            count = 1;
        }
        return article.race_count_total === count && article.person_type === personType;

    });
    if (!article) {
        return null;
    }
    return article;
};

export const useShopItemsCartHook = () => {
    const dispatch = useDispatch();
    const itemsCart = useTypedSelector(state => state.shopState.itemsCart);

    const resetCart = () => {
        dispatch(ShopAction.resetShoppingCart());
    }
    const removeFromCart = shopArticleItem => {
        dispatch<ThunkAction<any, any, any, any>>(ShopAction.fetchDeleteShopArticleItem(shopArticleItem)).then(r => {
            dispatch(ShopAction.getShopArticleItemsCart());
            dispatch(ShopAction.deleteShopArticleItem(shopArticleItem));
        });

    };

    const getCart = () => {
        dispatch(ShopAction.getShopArticleItemsCart());
    }

    const createCart = (profile) => {
        dispatch(ShopAction.createShopArticleItemsCart(profile));

    }
    const addToCart = (count, personType: PersonType, articles) => {
        const article = findArticle(count, personType, articles);
        if (count === 0) {
            return;
        }
        if (!article) {
            return;
        }
        const articleItem: ArticleItem = {
            article_id: article.article_id,
            articleType: article.type,
            quantity: 1,
            personType: personType,
        };

        if (itemsCart.shopArticleItemsCart) {
            dispatch(ShopAction.addShopArticleItem(articleItem, itemsCart.shopArticleItemsCart))
        }
    };

    return {
        resetCart,
        removeFromCart,
        addToCart,
        createCart,
        getCart,
    };
};
