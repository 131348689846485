import {createAction} from "helpers/createAction";
import {Friend} from "types/entities/Friend";
import {
    FriendReducerDeleteFriendRequestItemAction,
    FriendReducerPushFriendItemAction,
    FriendReducerPushFriendRequestItemAction,
    FriendReducerSetFriendItemsAction,
    FriendReducerSetFriendRequestItemsAction,
} from "store/referrals/FriendReducer";
import {ThunkAction} from "model/store/thunk";
import ajax from "helpers/ajax";
import {FriendInvitation} from "types/entities/FriendInvitation";
import {FriendRequest} from "types/entities/FriendRequest";
import {Profile} from "types/entities/Profile";
import { Driver } from "types/entities/Driver";

interface FriendResponse {
    friendList: Friend[] | [];
}

interface InvitationResponse {
    invitationList: FriendInvitation[] | [];
}

interface FriendRequestResponse {
    friendRequestList: FriendRequest[] | [];
}

class FriendAction {
    public static SET_FRIEND_REQUEST_ITEMS: string = "FriendAction.SetFriendRequestItems";
    public static PUSH_FRIEND_REQUEST_ITEM: string = "FriendAction.PushFriendRequestItem";
    public static DELETE_FRIEND_REQUEST_ITEM: string = "FriendAction.DeleteFriendRequestItem";
    public static SELECT_FRIEND_ITEM: string = "FriendAction.SelectFriendItem";
    public static UNSELECT_FRIEND_ITEM: string = "FriendAction.UnselectFriendItem";
    public static PUSH_FRIEND_ITEM: string = "FriendAction.PushFriendItem";
    public static SET_FRIEND_ITEMS: string = "FriendAction.SetFriendItems";
    public static SET_FRIEND_ITEM: string = "FriendAction.SetFriendItem";

    static pushFriendItem = (friendItem: Friend): FriendReducerPushFriendItemAction => {
        return createAction(FriendAction.PUSH_FRIEND_ITEM, friendItem);
    };

    static setFriendList = (friendItems: Friend[]): FriendReducerSetFriendItemsAction => {
        return createAction(FriendAction.SET_FRIEND_ITEMS, friendItems);
    };

    static pushFriendRequestItem = (friendRequestItem: FriendRequest): FriendReducerPushFriendRequestItemAction => {
        return createAction(FriendAction.PUSH_FRIEND_REQUEST_ITEM, friendRequestItem);
    };

    static setFriendRequestList = (friendRequestItems: FriendRequest[]): FriendReducerSetFriendRequestItemsAction => {
        return createAction(FriendAction.SET_FRIEND_REQUEST_ITEMS, friendRequestItems);
    };

    static deleteFriendRequestItem = (friendRequestItem: FriendRequest): FriendReducerDeleteFriendRequestItemAction => {
        return createAction(FriendAction.DELETE_FRIEND_REQUEST_ITEM, friendRequestItem);
    };

    static selectFriendItem = (friendItem: Friend) => {
        return createAction(FriendAction.SELECT_FRIEND_ITEM, friendItem);
    };

    static unselectFriendItem = (friendItem: Friend) => {
        return createAction(FriendAction.UNSELECT_FRIEND_ITEM, friendItem);
    };

    static fetchCloseFriend = (friend_id: number) => {
        return async (dispatch, getState): Promise<FriendResponse> => {
            const session = getState().sessionState.session;
            try {
                const r = await ajax.post("/referrals/close-friend", {friend_id}, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
                dispatch(FriendAction.pushFriendItem(r.friend));
                return r;
            } catch (err: any) {
                return err;
            }
        };
    };

    static fetchAllFriendsHandler = () => {
        return async (dispatch): Promise<any> => {
            return dispatch(FriendAction.fetchAllFriends()).then(async r => {
                if (r.friendList) {
                    const friends = await dispatch(FriendAction.setFriendList(r.friendList));
                    return friends;
                }
            });
        };
    };

    static sendUserNotification = (profile: Profile, friendItem: Friend, message) => {
        return async (dispatch, getState): Promise<InvitationResponse> => {
            const session = getState().sessionState.session;
            const driver: Driver = friendItem.me === 1 ? friendItem.driver2 as Driver : friendItem.driver1 as Driver;
            try {
                const r = await ajax.post(
                    "/user/sendUserNotification",
                    {
                        sender_id: profile.id,
                        driver_id: driver.id,
                        message,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${session.access_token}`,
                        },
                    }
                );
                return r;
            } catch (err: any) {
                return err;
            }
        };
    };

    static fetchFriendInvitations = (): ThunkAction<Promise<InvitationResponse>> => {
        return async (dispatch, getState): Promise<InvitationResponse> => {
            const session = getState().sessionState.session;
            try {
                const r = await ajax.get("/referrals/listFriendInvitations", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
                return r;
            } catch (err: any) {
                return err;
            }
        };
    };

    static confirmFriendRequest = (friendRequest: FriendRequest): ((dispatch: any, getState: any) => Promise<any>) => {
        return async (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;
            try {
                const r = await ajax.post(
                    "/referrals/confirmFriendRequest",
                    {
                        id: friendRequest.id,
                        driver_id: friendRequest.driver_id,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${session.access_token}`,
                        },
                    }
                );
                return r;
            } catch (err: any) {
                return err;
            }
        };
    };

    static getFriendRequests = (): ((dispatch, getState) => Promise<FriendRequestResponse>) => {
        return (dispatch) => {
            return dispatch(FriendAction.fetchFriendRequests()).then(r => {
                return dispatch(FriendAction.setFriendRequestList(r.friendRequestList));
            });
        };
    };

    static fetchFriendRequests = (): ThunkAction<Promise<FriendRequestResponse>> => {
        return async (dispatch, getState): Promise<FriendRequestResponse> => {
            const session = getState().sessionState.session;
            try {
                const r = await ajax.get("/referrals/listFriendRequests", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
                return r;
            } catch (err: any) {
                return err;
            }
        };
    };

    static fetchAllFriends = (): ThunkAction<Promise<FriendResponse>> => {
        return async (dispatch, getState): Promise<FriendResponse> => {
            const session = getState().sessionState.session;
            try {
                const r = await ajax.get("/referrals/listFriends", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
                return r;
            } catch (err: any) {
                return err;
            }
        };
    };
}

export default FriendAction;
