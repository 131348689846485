import ArticlesAction from "store/articles/ArticlesAction";
import {Article, ArticlePersonsType} from "types/entities";
import {PersonType} from "types/enums/PersonType";
import {ReducerResetAction} from "store/session/SessionReducer";
import SessionAction from "store/session/SessionAction";

interface ArticlesReducerSetArticlesAction {
    payload: Article[];
    type: typeof ArticlesAction.SET_ARTICLES;
}

type ArticlesReducerAction =
    ArticlesReducerSetArticlesAction
    | ReducerResetAction;

export interface ArticlesReducerState {
    all: Article[] | null;
    online: ArticlePersonsType | null;
    action: ArticlePersonsType | null;
}

class ArticlesReducer {
    initialState: ArticlesReducerState = {
        all: null,
        online: null,
        action: null,
    };

    reducer = (state: ArticlesReducerState = this.initialState, action: ArticlesReducerAction): ArticlesReducerState => {
        switch (action.type) {
            case ArticlesAction.SET_ARTICLES:
                const onlineTickets = action.payload?.filter(article => article.type === "online");
                const actionTickets = action.payload?.filter(article => article.type === "action");

                if (!onlineTickets && !actionTickets) {
                    return state;
                }

                return {
                    ...state,
                    all: action.payload,
                    online: {
                        ...state.online,
                        adult: onlineTickets?.filter(article => article.person_type === PersonType.Adult),
                        child: onlineTickets?.filter(article => article.person_type === PersonType.Child),
                    },
                    action: {
                        ...state.action,
                        adult: actionTickets?.filter(article => article.person_type === PersonType.Adult),
                        child: actionTickets?.filter(article => article.person_type === PersonType.Child),
                    },
                };
            case SessionAction.RESET:
                return this.initialState;
            default:
                return state;
        }
    };
}

export default ArticlesReducer;
