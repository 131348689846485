import React from "react";
import clsx from "clsx";

interface CardComponentProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string | boolean;
}

const CardComponent = (props: CardComponentProps) => {
    return <div className={clsx(props.className && props.className, "bg-white p-3 xl:p-4 rounded-md shadow-md")}>{props.children}</div>;
};

export default CardComponent;
