import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import {closeModal, postSupportMessage} from "helpers/actions";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import clsx from "clsx";

const SupportForm = () => {
    const [isDisabled, setIsDisabled] = useState(false);

    const supportFormSchema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email().required(),
        subject: yup.string(),
        message: yup.string().required(),
    });

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        resolver: yupResolver(supportFormSchema),
    });
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const onSubmit = data => {
        const {subject, ...rest} = data;
        if(subject === "") {
            data.subject = "Support message";
        }
        setIsDisabled(true);
        // @ts-ignore
        return dispatch(postSupportMessage(data)).then(response => {
            setIsDisabled(false);
            reset();
            return dispatch(closeModal());
        });
    };

    const inputClassName =
        "bg-gray-100 hover:bg-gray-200 active:bg-gray-200 focus:bg-gray-200 py-5 sm:py-7 px-5 mb-3 text-black placeholder-gray-800 self-stretch text-sm font-normal leading-8 h-9 rounded-2xl";
    return (
        <form autoComplete={"off"} className="flex flex-col" name={"supportForm"} onSubmit={handleSubmit(onSubmit)}>
            <input
                {...register("name")}
                className={clsx(inputClassName, errors.name ? "border-rose-600 outline-red-600" : "border-gray-200 outline-none")}
                name="name"
                placeholder={t("Enter your name")}
                type="text"
                required
            />
            <input
                {...register("email")}
                className={clsx(inputClassName, errors.email ? "border-rose-600 outline-red-600" : "border-gray-200 outline-none")}
                name="email"
                placeholder={t("Enter your email")}
                type="email"
                required
            />
            <input
                {...register("subject")}
                className={clsx(inputClassName, errors.subject ? "border-rose-600 outline-red-600" : "border-gray-200 outline-none")}
                name="subject"
                placeholder={t("Subject")}
                type="text"
            />
            <textarea
                {...register("message")}
                className="bg-gray-100 min-h-[160px] outline-none hover:bg-gray-200 active:bg-gray-200 focus:bg-gray-200 py-3 px-5 mb-3 placeholder-gray-800 self-stretch text-sm text-gray-800 font-normal leading-6 h-9 border border-gray-200 rounded-2xl"
                name="message"
                placeholder={t("Enter your message")}
                required
            />
            <button
                className="bg-dark-800 rounded-xl leading-8 py-2 px-5 text-white flex items-center justify-center uppercase text-center text-medium"
                disabled={isDisabled}
                id={"submitSupportForm"}
                type="submit"
            >
                {t("Submit")}
            </button>
        </form>
    );
};
export default SupportForm;
