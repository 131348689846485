import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router";
import {getQueryParams} from "helpers/helpers";
import PageNotFound from "pages/Front/PageNotFound/PageNotFound";

import Login from "pages/Front/Login/Login";
import Dashboard from "pages/Front/Dashboard/Dashboard";
import LoggedLayout from "layouts/LoggedLayout";

import {useTypedSelector} from "helpers/reducers";
import SessionAction from "store/session/SessionAction";
import {useDispatch} from "useDispatch";
import Logout from "pages/Front/Logout/Logout";
import ToastContainer from "components/ToastContainer";
import {useTranslation} from "react-i18next";
import {Flip, toast} from "react-toastify";
import UnloggedLayout from "./UnloggedLayout";
import ArticlesAction from "../store/articles/ArticlesAction";
import {clearCache, clearLocalStorage} from "helpers/store";
import {DEFAULT_BASENAME, HAS_BASENAME, IS_ONLY_BOOKING, languages} from "helpers/config";
import ReservationAction from "store/reservations/ReservationAction";
import Shop from "pages/Front/Shop/Shop";
import {BrowserRouter} from "react-router-dom";
import {Helmet} from "react-helmet";

const Checkin = React.lazy(() => import("pages/Front/Checkin/Checkin"));
const Reservations = React.lazy(() => import("pages/Front/Reservations/Reservations"));
const ReservationHistory = React.lazy(() => import("pages/Front/ReservationHistory/ReservationHistory"));
const Results = React.lazy(() => import("pages/Front/Results/Results"));
const Ranking = React.lazy(() => import("pages/Front/Ranking/Ranking"));
const PurchaseHistory = React.lazy(() => import("pages/Front/PurchaseHistory/PurchaseHistory"));
const Profile = React.lazy(() => import("pages/Front/Profile/Profile"));
const ForgottenPassword = React.lazy(() => import("pages/Front/ForgottenPassword/ForgottenPassword"));
const ConfirmBooking = React.lazy(() => import("pages/Front/Booking/ConfirmBooking/ConfirmBooking"));
const Register = React.lazy(() => import("pages/Front/Register/Register"));
const Referrals = React.lazy(() => import("pages/Front/Referrals/Referrals"));
const Vouchers = React.lazy(() => import("pages/Front/Vouchers/Vouchers"));
const Friends = React.lazy(() => import("pages/Front/Friends/Friends"));
// const Shop = React.lazy(() => import("pages/Front/Shop/Shop"));
const Booking = React.lazy(() => import("pages/Front/Booking/Booking"));

const FrontLayout = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const session = useTypedSelector(state => state.sessionState.session);
    const profile = useTypedSelector(state => state.sessionState.profile);
    const language = profile && languages.find(language => language.code === profile.lang);
    const location = useLocation();
    const [shopActive, setShopActive] = useState(false);
    // useEffect(() => {
    //     document.title = location.pathname.includes("/booking") ? "Booking" : "Online profile";
    // }, [location.pathname]);

    useEffect(() => {
        const queryParams = getQueryParams();
        if (queryParams?.connected) {
            toast.success(t("Connected successfully"));
        }
        if (session && session.user && session.user.user_type && session.user.user_type !== "guest") {
            dispatch(SessionAction.fetchProfile())
                .then(r => {
                    if (language) {
                        dispatch(SessionAction.setLanguage(language));
                    }
                    if (r.status === 401) {
                        dispatch(SessionAction.logout()).then(r => {
                            clearLocalStorage();
                            clearCache();
                            window.location.href = "/";
                        });
                    }
                })
                .catch(err => {
                    console.log("error on session fetchProfile");
                });

            if (!location.pathname.includes("booking")) {
                dispatch(ReservationAction.fetchReservations("current", 1, 10));
                dispatch(ArticlesAction.fetchArticlesWithHall());
                dispatch(SessionAction.fetchNotifications()).then(r => {});
            }
        }
    }, [session]);

    useEffect(() => {
        if (session && session.user && session.user.user_type && session.user.user_type !== "guest") {
            dispatch(SessionAction.fetchNotifications()).then(r => {});
        }
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <BrowserRouter basename={HAS_BASENAME ? "/" + DEFAULT_BASENAME : "/"}>
            <Switch>
                <Route path="/checkin" exact>
                    <Helmet>
                        <title>{t("Checkin")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Checkin />
                    </LoggedLayout>
                </Route>
                {/*<Route path="/admin/map" exact>*/}
                {/*    <AdminLayout>*/}
                {/*        <AdminDashboard />*/}
                {/*    </AdminLayout>*/}
                {/*</Route>*/}
                {/*<Route path="/admin/stats/:hallId">*/}
                {/*    <AdminLayout>*/}
                {/*        <AdminStatisticsCalendar />*/}
                {/*    </AdminLayout>*/}
                {/*</Route>*/}
                {/*<Route path="/admin/details/:slug">*/}
                {/*    <AdminLayout>*/}
                {/*        <AdminDataTable />*/}
                {/*    </AdminLayout>*/}
                {/*</Route>*/}
                <Route path="/booking">
                    <Helmet>
                        <title>{t("Booking")}</title>
                    </Helmet>
                    <UnloggedLayout>
                        <Booking />
                    </UnloggedLayout>
                </Route>
                {/*<Route path="/admin/data">*/}
                {/*    <AdminLayout>*/}
                {/*        <CsvParser />*/}
                {/*    </AdminLayout>*/}
                {/*</Route>*/}
                <Route path="/dashboard" exact>
                    <Helmet>
                        <title>{t("Dashboard")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Dashboard />
                    </LoggedLayout>
                </Route>
                <Route path="/" exact>
                    <Helmet>
                        <title>{t("Online profile")}</title>
                    </Helmet>
                    {session && session.user && session.user.user_type !== "guest" ? (
                        <LoggedLayout>
                            <Dashboard />
                        </LoggedLayout>
                    ) : (
                        <UnloggedLayout>
                            <Login />
                        </UnloggedLayout>
                    )}
                    {IS_ONLY_BOOKING && <Redirect to={"/booking"} />}
                </Route>
                <Route path="/logout" exact>
                    <Helmet>
                        <title>{t("Logout")}</title>
                    </Helmet>
                    <UnloggedLayout>
                        <Logout />
                    </UnloggedLayout>
                </Route>
                <Route path="/register">
                    <Helmet>
                        <title>{t("Register")}</title>
                    </Helmet>
                    <UnloggedLayout>
                        <Register />
                    </UnloggedLayout>
                </Route>
                <Route path="/forgotten-password/:access_token?/:app_id?/:m_clk_id?">
                    <Helmet>
                        <title>{t("Forgotten password")}</title>
                    </Helmet>
                    <UnloggedLayout>
                        <ForgottenPassword />
                    </UnloggedLayout>
                </Route>
                <Route path="/confirm-booking/:package_cart_guid?/:package_cart_token?/:app_id?/:m_clk_id?">
                    <Helmet>
                        <title>{t("Booking confirmation")}</title>
                    </Helmet>
                    <UnloggedLayout>
                        <ConfirmBooking />
                    </UnloggedLayout>
                </Route>
                <Route path="/results" exact>
                    <Helmet>
                        <title>{t("Results")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Results />
                    </LoggedLayout>
                </Route>
                <Route path="/referrals">
                    <Helmet>
                        <title>{t("Referrals")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Referrals />
                    </LoggedLayout>
                </Route>
                <Route path="/friends">
                    <Helmet>
                        <title>{t("Friends")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Friends />
                    </LoggedLayout>
                </Route>
                <Route path="/vouchers">
                    <Helmet>
                        <title>{t("Vouchers")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Vouchers />
                    </LoggedLayout>
                </Route>
                <Route path="/shop/paid">
                    <Helmet>
                        <title>{t("Shop")}</title>
                    </Helmet>
                    <LoggedLayout onShopClick={() => setShopActive(!shopActive)}>
                        <Shop param={1} setShopActive={setShopActive} shopActive={shopActive} />
                    </LoggedLayout>
                </Route>

                <Route path="/shop/:personType?" exact>
                    <Helmet>
                        <title>{t("Shop")}</title>
                    </Helmet>
                    <LoggedLayout onShopClick={() => setShopActive(!shopActive)}>
                        <Shop setShopActive={setShopActive} shopActive={shopActive} />
                    </LoggedLayout>
                </Route>
                <Route path="/ranking" exact>
                    <Helmet>
                        <title>{t("Ranking")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Ranking />
                    </LoggedLayout>
                </Route>
                <Route path="/purchase-history" exact>
                    <Helmet>
                        <title>{t("Purchase history")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <PurchaseHistory />
                    </LoggedLayout>
                </Route>
                <Route path="/profile" exact>
                    <Helmet>
                        <title>{t("Profile")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Profile />
                    </LoggedLayout>
                </Route>
                <Route path="/reservations">
                    <Helmet>
                        <title>{t("Reservations")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <Reservations />
                    </LoggedLayout>
                </Route>
                <Route path="/reservation-history">
                    <Helmet>
                        <title>{t("Reservation history")}</title>
                    </Helmet>
                    <LoggedLayout>
                        <ReservationHistory />
                    </LoggedLayout>
                </Route>
                <Route>
                    <Helmet>
                        <title>{t("Page not found")}</title>
                    </Helmet>
                    <UnloggedLayout>
                        <PageNotFound />
                    </UnloggedLayout>
                </Route>
            </Switch>
            <ToastContainer autoClose={5000} draggablePercent={50} newestOnTop={true} pauseOnFocusLoss={false} transition={Flip} />
        </BrowserRouter>
    );
};

export default FrontLayout;
