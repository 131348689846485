import dayjs, {Dayjs} from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isBetween from "dayjs/plugin/isBetween";
import toObject from "dayjs/plugin/toObject";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/cs";
import "dayjs/locale/en";
import { LOCALE } from "helpers/config";
import {range} from "utils/utility";


interface DateTimeFormatOptions {
    localeMatcher?: "best fit" | "lookup" | undefined;
    weekday?: "long" | "short" | "narrow" | undefined;
    era?: "long" | "short" | "narrow" | undefined;
    year?: "numeric" | "2-digit" | undefined;
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
    day?: "numeric" | "2-digit" | undefined;
    hour?: "numeric" | "2-digit" | undefined;
    minute?: "numeric" | "2-digit" | undefined;
    second?: "numeric" | "2-digit" | undefined;
    timeZoneName?: "short" | "long" | "shortOffset" | "longOffset" | "shortGeneric" | "longGeneric" | undefined;
    formatMatcher?: "best fit" | "basic" | undefined;
    hour12?: boolean | undefined;
    timeZone?: string | undefined;
}

interface Date {}

type DayJsDate = dayjs.ConfigType;

export interface YearMonth {
    year: number,
    month: number,
}

class Dates {
    constructor() {
        dayjs.extend(weekday);
        dayjs.extend(isBetween);
        dayjs.extend(toObject);
        dayjs.extend(customParseFormat);
        dayjs.locale("en");
    }

    date = (date?: DayJsDate, defaultValue?: any, format?: string): string => {
        if (!this.dayjs(date).isValid()) {
            return date || defaultValue;
        }
        return this.dayjs(date).format(format || "D. M. YYYY");
    };

    ymd = (date?: DayJsDate, format?: string): string => {
        return this.date(date, null, format ?? "YYYY-MM-DD");
    };

    time = (date?: DayJsDate): string | null => {
        if (!this.dayjs(date).isValid()) {
            return null;
        }
        return this.dayjs(date).format("H:mm");
    };

    hour = (date?: DayJsDate): number => {
        return parseInt(this.dayjs(date).format("H"));
    };

    minute = (date?: DayJsDate): number => {
        return parseInt(this.dayjs(date).format("mm"));
    };

    hoursDifference = (date1: DayJsDate, date2: DayJsDate) => {
        const dateObj = this.dayjs(date1);
        if (!dateObj.isValid()) {
            return null;
        }
        if (dateObj.diff(date2, "hour") < 0) {
            return "";
        }
        const hourDiff = dateObj.diff(date2, "hour") - dateObj.diff(date2, "day") * 24;
        const minDiff = dateObj.diff(date2, "minute") - dateObj.diff(date2, "hour") * 60;
        return (hourDiff < 10 ? "0" + hourDiff : hourDiff) + ":" + (minDiff < 10 ? "0" + minDiff : minDiff);
    };

    daysDifference = (date1: DayJsDate, date2: DayJsDate): number | null => {
        if (!date2) {
            date2 = this.dayjs();
        }
        const dateObj = this.dayjs(date1);
        if (!dateObj.isValid()) {
            return null;
        }
        return dateObj.diff(date2, "day", true);
    };

    daysInText = (days: string) => {
        const daysInt = Math.abs(parseInt(days));
        if (daysInt === 0) {
            return " - today";
        }
        if (daysInt === 1) {
            return "day";
        }
        if (daysInt >= 2 && daysInt <= 4) {
            return "days";
        }
        if (daysInt >= 5) {
            return "days";
        }
        return "?";
    };

    daysDifferenceAsText = (date: DayJsDate, date2: DayJsDate): string => {
        const daysDiff = this.daysDifference(date, date2);
        if (daysDiff === null) {
            return "?";
        }

        if (daysDiff > 0 && daysDiff < 1 && this.date(date) !== this.date(date2)) {
            return "Tomorrow";
        }

        if (Math.floor(daysDiff) === 0) {
            return "Today";
        }

        const daysDiffText = daysDiff >= 0 ? "+" + daysDiff : "" + daysDiff;

        return Math.floor(daysDiff) + " " + this.daysInText(daysDiffText) + " " + this.hoursDifference(date, date2);
    };

    dayjs = (date?: DayJsDate): Dayjs => {
        let dateObj = dayjs(date);

        if (typeof date === "number") {
            dateObj = dayjs.unix(date);
        }
        return dateObj;
    };

    dayNameTranslated(dayName): string {
        return {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
        }[dayName];
    }

    timeWithoutZero(open) {
        return dayjs(open, "H:i").format("H:mm");
    }

    isToday = (date: globalThis.Date): boolean => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
    }

    weekDay = (date): number => {
        return new Date(date).getDay();
    }

    weekdayNameTranslated(dayName): string {
        return {
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday",
            7: "Sunday",
        }[dayName];
    }

    addDays = (date: string | number, days: number): Date => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    intlDateFormat = (timestamp: number, options: DateTimeFormatOptions | {dateStyle: "long" | "short" | "full" | "medium" | undefined}): string => {
        return Intl.DateTimeFormat(LOCALE, options).format(timestamp)
    }

    generateYearMonth = (year): YearMonth[] => {
        const months = range(12, 1);
        return months.map(month => {
            return {year: year, month: month}
        })
    }

}
export default new Dates();
