import {Article} from "types/entities/Article";
import {PackageItemExtra} from "types/entities/PackageItem";

export interface VoucherItem {
    id: number,
    guid: string,
    hall_id: number,
    article_id: number;
    article: Article,
    code: string,
    amount_left: number,
    amount_total: number,
    extra: PackageItemExtra,
    update_sequence: number,
    blocked_at: string | null
    created_at: string | null
    deleted_at: string | null
    expires_at: string | null
    updated_at: string | null,
}

export enum VoucherTypeFilter {
    Activity = "activity",
    Article = "article",
    All = "all",
}

export enum VoucherDiscountType {
    Race = "race",
    Activity = "activity",
    Value = "value",
    Percent = "percent",
    Credit = "credit",
    General = "general"
}

export interface VoucherType {
    label: string;
    value: number;
    type: VoucherDiscountType;
    filterBy: VoucherTypeFilter[];
    valueFormatted: string;
}