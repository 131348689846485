import React, {Suspense, useEffect, useRef} from "react";
import NavBarTop from "pages/Front/components/NavBarTop";
import NavBarLeft from "pages/Front/components/NavBarLeft";
import ResultPopup from "../pages/Front/components/ResultPopup";
import {useTypedSelector} from "helpers/reducers";
import "assets/ExtraStyle.css";
import Popup from "pages/Front/components/Popup";
import clsx from "clsx";
import NavBarBottom from "../pages/Front/components/NavBarBottom";
import {isMobileOnly} from "react-device-detect";
import {Redirect, useLocation} from "react-router";
import LogRocket from "logrocket";

interface LoggedLayoutProps {
    children: React.ReactNode | React.ReactNode[];
    onShopClick?: () => void;
}

const LoggedLayout = (props: LoggedLayoutProps) => {
    const scrollRef = useRef(null);
    const session = useTypedSelector(state => state.sessionState.session);
    // const blur = useTypedSelector(state => state.blur);
    const location = useLocation();

    if (!session || session.user.user_type === "guest") {
        if (location.pathname !== "/") {
            return <Redirect to={"/"} />;
        }
    }
    const onShopClick = () => {
        if (props.onShopClick) {
            props.onShopClick();
        }
        return null;
    };

    useEffect(() => {
        if(session) {
        LogRocket.identify("" + session.uid, {
            name: session.user.name + " " + session.user.last_name,
            username: session.user.username,

            // Add your own custom user variables here, ie:
            user_type: session.user.user_type
        });
        }
    }, [session])
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="LoggedLayout relative bg-white h-full flex flex-col">
                <div className="LoggedLayoutOuter h-full flex flex-col flex-grow z-20 rounded-4xl items-stretch">
                    <div
                        className={clsx("LoggedLayoutInner flex-grow relative h-full flex flex-col justify-center items-stretch lg:pt-5vw lg:pl-5vw ")}
                        role="document"
                        // style={{filter: blur && "blur(2px)"}}
                        onClick={() => {
                            // if (blur) {
                            //     dispatch(setBlur(false));
                            //     dispatch(closeModal());
                            // }
                        }}
                    >
                        <NavBarTop isMobile={isMobileOnly} onShopClick={() => onShopClick()} />
                        {!isMobileOnly && <NavBarLeft />}
                        {isMobileOnly && <NavBarBottom />}
                        <div className="LoggedLayoutInner__content flex flex-col flex-grow items-center relative z-20 sm:py-12 px-4 mt-12 mb-16 sm:mb-0 md:ml-16 lg:ml-16 lg:mt-16 2xl:ml-20 2xl:mt-20 shadow-inset rounded-tl-4xl overflow-hidden">
                            <div className={"absolute inset-0"}>
                                <div ref={scrollRef} className={"flex flex-col relative h-full overflow-y-scroll sm:overflow-y-auto pt-4 2xl:pt-6 sm:pb-2 2xl:pb-6 px-4 sm:px-6 2xl:px-8 flex-grow items-center"}>
                                    {props.children}
                                </div>
                            </div>
                            <div className="LoggedLayoutInnerBackground pointer-events-none absolute inset-0 overflow-hidden block lg:w-95vw rounded-tl-4xl">
                                <div className="LoggedLayoutInnerBackground__gradient absolute inset-0 z-20 opacity-30 softlight softlight-gradient" />
                                <div className="LoggedLayoutInnerBackground__shadow absolute inset-0 z-1 bg-whitesmoke opacity-90 shadow-dash" />
                            </div>
                        </div>
                    </div>
                </div>
                <ResultPopup />
                <Popup />
                <div className="ref"></div>
            </div>
        </Suspense>
    );
};
export default LoggedLayout;
