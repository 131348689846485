import React, {useEffect, useState} from "react";
import {closeModal} from "helpers/actions";
import {useDispatch} from "useDispatch";
import {useTypedSelector} from "helpers/reducers";

export enum ModalSize {
    Small = "small",
    RoundedFullscreen = "roundedFullscreen",
    FullScreen = "fullscreen",
}

const Popup = () => {
    const popup = useTypedSelector(state => state.modal);
    const blur = useTypedSelector(state => state.blur);
    const [reload, setReload] = useState(performance.navigation.type);
    const isOpen = popup.isOpen || false;
    const [isLoading, setIsLoading] = useState(true);
    //const popup = null;
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.performance) {
            if (reload == 1) {
                dispatch(closeModal());
            }
        }
        const close = e => {
            if (e.keyCode === 27) {
                closePopup();
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    const closePopup = () => {
        dispatch(closeModal());
    };
    useEffect(() => {
        if (popup.component) {
            setTimeout(() => {
                setIsLoading(false);
            }, 4);
        } else {
            setIsLoading(true);
        }
    }, [popup]);

    if (!popup.component) {
        return null;
    }

    if (!React.isValidElement(popup.component)) {
        return null;
    }

    return (
        <div
            className="Popup flex overscroll-contain fixed inset-0 right-0 z-50 w-full h-full justify-center items-center transform-gpu bg-gray-800 bg-opacity-80"
            style={{
                transition: "300ms",
                transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
                opacity: isOpen && !isLoading ? 1 : 0,
                display: isOpen ? "flex" : "none",
            }}
        >
            <div
                className={"fixed inset-0 flex justify-center items-center z-40 "}
                id={"CloseFilterModal"}
                style={{filter: blur && "blur(2px)"}}
                onClick={() => dispatch(closeModal())}
            />
            <div className="absolute w-auto flex justify-center items-center z-50 opacity-1 transform-gpu p-4">{popup.component}</div>
        </div>
    );
};

export default Popup;
