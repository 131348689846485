import {Language} from "types/entities/Language";
import {HallCode} from "types/enums/HallCode";

export const GOOGLE_MAPS_PUBLIC_KEY = "AIzaSyAfS1EvTnPRzShMAZjsreykidNDGxBijws";
// export const GOOGLE_ANALYTICS_KEY = "UA-60205697-4";

export const PROTOCOL = "https";
export const IS_DEV = document.location.port?.includes("30") || false;
export const IS_REMOTE_DEV = document.location.hostname.includes("192.168") || document.location.hostname.includes("online.tomkart.com") || true;
// export const IS_DEV = true;
export const IS_PROD = !IS_DEV || !IS_REMOTE_DEV;
export const ROOT_STORAGE_DOMAIN = !IS_PROD ? "cloud.tomkart.com" : "cloud.tomkart.com";
// export const ROOT_STORAGE_DOMAIN = "cloud.tomkart.com";
export const ROOT_STORAGE_WEB = `${PROTOCOL}://${ROOT_STORAGE_DOMAIN}/`;
export const ROOT_API_WEB = `${PROTOCOL}://${ROOT_STORAGE_DOMAIN}`;
export const ROOT_API = ROOT_API_WEB + "/api/";
export const DEFAULT_HALL: HallCode = HallCode.Budapest;

export const VOUCHERS_LIMIT: number = 100; //limit to max 100 vouchers

let HALL_CURRENCY;
let HALL_LOCALE;
let HALL_TAX;
let HALL_DEFAULT_LANGUAGE;
let HALL_DEFAULT_HALL_ID;
let HALL_DEFAULT_RESERVATION_DAYS_LIMIT;
let HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW;
let HALL_OFFICIAL_NAME;
let HALL_RESERVATION_ARRIVAL_MINUTES;
let HALL_GOOGLE_ANALYTICS_KEY;
let HALL_GOOGLE_TAG_MANAGER_ID;
let HALL_DEFAULT_BASENAME;
let HALL_LANGUAGES;
let SHOW_PER_PERSON_PRICE;
let SHOW_READ_TERMS_BUTTON;
let ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT;
let HALL_IS_ONLY_BOOKING;
let HALL_HAS_BASENAME;


if ((DEFAULT_HALL as HallCode) === HallCode.Winterthur) {
    HALL_CURRENCY = "CHF";
    HALL_LOCALE = "de-CH";
    HALL_TAX = "7.7";
    HALL_DEFAULT_LANGUAGE = "de";
    HALL_DEFAULT_HALL_ID = 1;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 3;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 3;
    HALL_OFFICIAL_NAME = "Tempodrom Winterthur";
    HALL_RESERVATION_ARRIVAL_MINUTES = 20;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-90898326-2";
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-K499QS6";
    HALL_DEFAULT_BASENAME = "winterthur";
    HALL_LANGUAGES = [
        {
            id: 1,
            code: "en",
            locale: "en-GB",
            name: "English",
        },
        {
            id: 2,
            code: "de",
            locale: "de-DE",
            name: "Deutsch",
        },
        // {
        //     id: 3,
        //     code: "it",
        //     locale: "it-IT",
        //     name: "Italiano",
        // },
        // {
        //     id: 3,
        //     code: "nl",
        //     locale: "nl-NL",
        //     name: "Netherlands",
        // },
    ];
    SHOW_PER_PERSON_PRICE = false;
    SHOW_READ_TERMS_BUTTON = false;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = true;
    HALL_HAS_BASENAME = true;
}

if ((DEFAULT_HALL as HallCode) === HallCode.Spreitenbach) {
    HALL_CURRENCY = "CHF";
    HALL_LOCALE = "de-CH";
    HALL_TAX = "7.7";
    HALL_DEFAULT_LANGUAGE = "de";
    HALL_DEFAULT_HALL_ID = 2;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 3;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 3;
    HALL_OFFICIAL_NAME = "Kartbahn Spreitenbach";
    HALL_RESERVATION_ARRIVAL_MINUTES = 15;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-90898326-3";
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-K499QS6";
    HALL_DEFAULT_BASENAME = "spreitenbach";
    HALL_LANGUAGES = [
        {
            id: 1,
            code: "en",
            locale: "en-GB",
            name: "English",
        },
        {
            id: 2,
            code: "de",
            locale: "de-DE",
            name: "Deutsch",
        },
        // {
        //     id: 3,
        //     code: "it",
        //     locale: "it-IT",
        //     name: "Italiano",
        // },
    ];
    SHOW_PER_PERSON_PRICE = false;
    SHOW_READ_TERMS_BUTTON = false;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = true;
    HALL_HAS_BASENAME = true;
}

if ((DEFAULT_HALL as HallCode) === HallCode.Budapest) {
    HALL_CURRENCY = "HUF";
    HALL_LOCALE = "hu-HU";
    HALL_TAX = "25";
    HALL_DEFAULT_LANGUAGE = "hu";
    HALL_DEFAULT_HALL_ID = 4;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 5;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 3;
    HALL_OFFICIAL_NAME = "FlashKart Budapest";
    HALL_RESERVATION_ARRIVAL_MINUTES = 15;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-2773318-72"; //not changed
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-NQJNVGG";//not changed
    HALL_DEFAULT_BASENAME = "blaha";
    HALL_LANGUAGES = [
        {
            id: 1,
            code: "hu",
            locale: "hu-HU",
            name: "Magyar",
        },
        {
            id: 2,
            code: "en",
            locale: "en-GB",
            name: "English",
        }
    ];
    SHOW_PER_PERSON_PRICE = false;
    SHOW_READ_TERMS_BUTTON = false;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = false;
    HALL_HAS_BASENAME = false;
}


if ((DEFAULT_HALL as HallCode) === HallCode.BudapestKoki) {
    HALL_CURRENCY = "HUF";
    HALL_LOCALE = "hu-HU";
    HALL_TAX = "25";
    HALL_DEFAULT_LANGUAGE = "hu";
    HALL_DEFAULT_HALL_ID = 7;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 5;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 3;
    HALL_OFFICIAL_NAME = "FlashKart Budapest";
    HALL_RESERVATION_ARRIVAL_MINUTES = 15;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-2773318-72"; //not changed
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-NQJNVGG";//not changed
    HALL_DEFAULT_BASENAME = "koki";
    HALL_LANGUAGES = [
        {
            id: 1,
            code: "en",
            locale: "en-GB",
            name: "English",
        },
        {
            id: 2,
            code: "hu",
            locale: "hu-HU",
            name: "Magyar",
        }
    ];
    SHOW_PER_PERSON_PRICE = false;
    SHOW_READ_TERMS_BUTTON = false;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = true;
    HALL_HAS_BASENAME = true;
}

if ((DEFAULT_HALL as HallCode) === HallCode.Trencin) {
    HALL_CURRENCY = "EUR";
    HALL_LOCALE = "sk-SK";
    HALL_TAX = "20";
    HALL_DEFAULT_LANGUAGE = "sk";
    HALL_DEFAULT_HALL_ID = 3;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 5;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 5;
    HALL_OFFICIAL_NAME = "Volt Racing Trenčín";
    HALL_RESERVATION_ARRIVAL_MINUTES = 15;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-2773318-72"; //not changed
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-NQJNVGG";//not changed
    HALL_DEFAULT_BASENAME = "trencin";
    HALL_LANGUAGES = [
        {
            id: 1,
            code: "en",
            locale: "en-GB",
            name: "English",
        },
        {
            id: 2,
            code: "sk",
            locale: "sk-SK",
            name: "Slovensky",
        }
    ];
    SHOW_PER_PERSON_PRICE = false;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = true;
    HALL_HAS_BASENAME = true;
}

if ((DEFAULT_HALL as HallCode) === HallCode.Zwolle) {
    HALL_CURRENCY = "EUR";
    HALL_LOCALE = "nl-NL";
    HALL_TAX = "9";
    HALL_DEFAULT_LANGUAGE = "nl";
    HALL_DEFAULT_HALL_ID = 5;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 7;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 0;
    HALL_OFFICIAL_NAME = "Kobalt Zwolle";
    HALL_RESERVATION_ARRIVAL_MINUTES = 15;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-2773318-72";
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-NQJNVGG";
    HALL_DEFAULT_BASENAME = "kobalt";
    HALL_LANGUAGES = [
        {
            id: 1,
            code: "en",
            locale: "en-GB",
            name: "English",
        },
        {
            id: 2,
            code: "de",
            locale: "de-DE",
            name: "Deutsch",
        },
        {
            id: 3,
            code: "nl",
            locale: "nl-NL",
            name: "Netherlands",
        },
    ];
    SHOW_PER_PERSON_PRICE = true;
    SHOW_READ_TERMS_BUTTON = true;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = false;
    HALL_HAS_BASENAME = false;
}

if ((DEFAULT_HALL as HallCode) === HallCode.Phpcode) {
    HALL_CURRENCY = "EUR";
    HALL_LOCALE = "nl-NL";
    HALL_TAX = "9";
    HALL_DEFAULT_LANGUAGE = "nl";
    HALL_DEFAULT_HALL_ID = 420;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 7;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 0;
    HALL_OFFICIAL_NAME = "PHPCode";
    HALL_RESERVATION_ARRIVAL_MINUTES = 15;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-2773318-72"; //not changed
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-NQJNVGG";//not changed
    HALL_DEFAULT_BASENAME = "phpcode";
    HALL_LANGUAGES = [
        {
            id: 1,
            code: "en",
            locale: "en-GB",
            name: "English",
        },
        {
            id: 2,
            code: "de",
            locale: "de-DE",
            name: "Deutsch",
        }
    ];
    SHOW_PER_PERSON_PRICE = true;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = true;
    HALL_HAS_BASENAME = true;
}

if ((DEFAULT_HALL as HallCode) === HallCode.Dev) {
    HALL_CURRENCY = "EUR";
    HALL_LOCALE = "nl-NL";
    HALL_TAX = "9";
    HALL_DEFAULT_LANGUAGE = "nl";
    HALL_DEFAULT_HALL_ID = 420;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 7;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 0;
    HALL_OFFICIAL_NAME = "Dev TOMKART";
    HALL_RESERVATION_ARRIVAL_MINUTES = 15;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-2773318-72"; //not changed
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-NQJNVGG";//not changed
    HALL_DEFAULT_BASENAME = "devtomkart";
    SHOW_PER_PERSON_PRICE = true;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = true;
    HALL_HAS_BASENAME = true;
}

if ((DEFAULT_HALL as HallCode) === HallCode.Corner) {
    HALL_CURRENCY = "EUR";
    HALL_LOCALE = "nl-NL";
    HALL_TAX = "9";
    HALL_DEFAULT_LANGUAGE = "nl";
    HALL_DEFAULT_HALL_ID = 422;
    HALL_DEFAULT_RESERVATION_DAYS_LIMIT = 7;
    HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW = 0;
    HALL_OFFICIAL_NAME = "Corner";
    HALL_RESERVATION_ARRIVAL_MINUTES = 15;
    HALL_GOOGLE_ANALYTICS_KEY = "UA-2773318-72"; //not changed
    HALL_GOOGLE_TAG_MANAGER_ID = "GTM-NQJNVGG";//not changed
    HALL_DEFAULT_BASENAME = "corner";
    HALL_LANGUAGES = [
        {
            id: 1,
            code: "en",
            locale: "en-GB",
            name: "English",
        },
        {
            id: 2,
            code: "de",
            locale: "de-DE",
            name: "Deutsch",
        }
    ];
    SHOW_PER_PERSON_PRICE = true;
    ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = false;
    HALL_IS_ONLY_BOOKING = true;
    HALL_HAS_BASENAME = true;
}

/* apply hall based variable values */
export const CURRENCY = HALL_CURRENCY;
export const LOCALE = HALL_LOCALE;
export const TAX = HALL_TAX;
export const DEFAULT_LANGUAGE = HALL_DEFAULT_LANGUAGE;
export const DEFAULT_RESERVATION_DAYS_LIMIT = HALL_DEFAULT_RESERVATION_DAYS_LIMIT;
export const DEFAULT_HALL_ID = IS_DEV ? HALL_DEFAULT_HALL_ID : HALL_DEFAULT_HALL_ID;
export const DEFAULT_OFFICIAL_NAME = HALL_OFFICIAL_NAME;
export const DEFAULT_RESERVATION_ARRIVAL_MINUTES = HALL_RESERVATION_ARRIVAL_MINUTES;
export const GOOGLE_ANALYTICS_KEY = HALL_GOOGLE_ANALYTICS_KEY;
export const GOOGLE_TAG_MANAGER_ID = HALL_GOOGLE_TAG_MANAGER_ID;
export const DEFAULT_BASENAME = HALL_DEFAULT_BASENAME;
export const DEFAULT_RESERVATION_DAYS_FROM_NOW = HALL_DEFAULT_RESERVATION_DAYS_FROM_NOW;
export const DEFAULT_SHOW_PER_PERSON_PRICE = SHOW_PER_PERSON_PRICE;
export const DEFAULT_SHOW_READ_TERMS_BUTTON = SHOW_READ_TERMS_BUTTON;
export const DEFAULT_ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT = ENABLE_ADJACENT_TIMESLOT_HIGHLIGHT;
export const ANIMATIONS_ENABLED = IS_PROD;
export const IS_PAYMENT_TEST_MODE_ON = false;
export const PAYMENT_TEST = !IS_PROD && IS_PAYMENT_TEST_MODE_ON;
export const GENERIC_ARTICLE_ID = 3000;
export const IS_UPSELL_ALLOWED = true;
export const IS_VOUCHER_ALLOWED = true;
export const TIMER_COUNT = 60 * 1000 * 10;
export const PROJECT_VERSION = process.env.REACT_APP_VERSION;
export const MAXIMUM_POINTS_COUNT = 10;
export const DEFAULT_COINS_COUNT = 1500;
export const IS_ONLY_BOOKING: boolean = HALL_IS_ONLY_BOOKING;
export const HAS_BASENAME: boolean = HALL_HAS_BASENAME;

export const languages: Language[] = HALL_LANGUAGES;

export interface Translations {
    en: string;
    de: string;
    nl: string;
    it?: string;
    hu?: string;
    sk?: string;
}
