import React from "react";
import {Hall} from "types/entities/Hall";

interface HallProps {
    hall: Hall;
}

const HallLogo = (props: HallProps) => {
    return (
        <img alt={props.hall.name} className="w-full min-w-[100px] pl-2 py-1 sm:py-0 max-h-[36px] sm:max-h-[40px] xl:max-h-[50px] object-contain " src={props.hall.image} />
    )
}

export default HallLogo;