import ajax from "helpers/ajax";
import {Action, createAction} from "helpers/createAction";
import {Activity} from "types/entities/Activity";
import {Package} from "types/entities/Package";
import {
    BookingReducerSetActivitiesAction,
    BookingReducerSetCustomerAction,
    BookingReducerSetFilterAction,
    BookingReducerSetPackageAction,
    BookingReducerSetPackageCartAction,
    BookingReducerSetPackagesAction,
    BookingReducerSetTimeslotsAction,
    BookingReducerPushUpsellAction,
    BookingReducerSetTimeslotsAllowedAction,
    BookingReducerSetUpsellAction,
    BookingReducerPushReservationNoteAction,
    BookingReducerResetReservationNoteAction,
} from "store/booking/BookingReducer";
import {BookingActivitiesResponse, BookingPackagesResponse, BookingPackagesTimeslotsResponse} from "types/responses";
import {ThunkAction} from "model/store/thunk";
import {BookingFilter, BookingTimeslot} from "types/app";
import {PackageItem} from "types/entities/PackageItem";
import {Company} from "types/entities/Company";
import {PackagesCart} from "types/entities/PackagesCart";
import {Payment} from "types/entities/Payment";
import {DEFAULT_HALL_ID} from "helpers/config";
import Dates from "utils/Dates";
import {BookingInitCartResponse} from "types/responses/BookingInitCartResponse";
import {CompanyInputs} from "pages/Front/Booking/components/BookingCustomer/BookingCustomerForm";
import {Timeslot} from "types/entities/Timeslot";

export type BookingPackagesInput = {
    date: string;
    adults: number;
    children: number;
    activity_ids: number[];
};

export type PackagesTimeSlots = {
    package_id: number;
    date: string;
    adults: number;
    children: number;
    upsell: PackageUpsellItem[];
    hours_count?: number;
};

export type BookPackageParams = {
    package_id: number;
    adults: number;
    children: number;
    upsell: PackageUpsellItem[];
};
export type BookPackageUpsellParams = {
    timeslot_id: number | null;
    package_id: number;
    adults: number;
    children: number;
    upsell: PackageUpsellItem[];
};

export type PackageUpsellItem = {package_item_id: number; upsell_option: number; quantity: number};

export type BookTimeslotParams = {
    package_id: number;
    timeslot_id: number | null;
    adults: number;
    children: number;
    upsell: PackageUpsellItem[];
    hours_count?: number;
};

export type SetCustomerParams = {
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    city: string;
    phone: string;
    street_address: string;
    postal_code: string;
};

export type ReservationNote = {
    id: number;
    guid: string;
    reservation_id: number;
    note: string;
    creator_id: number | null;
    created_at: string;
    updated_at: string;
};

export type PackageUpsell = {
    packageItem: PackageItem;
    upsell_option: number;
    count: number;
};

class BookingAction {
    static readonly SET_ACTIVITIES = "BookingAction.SetActivities";
    static readonly SET_PACKAGES = "BookingAction.SetPackages";
    static readonly SET_TIMESLOTS = "BookingAction.SetTimeslots";
    static readonly SET_TIMESLOTS_ALLOWED = "BookingAction.SetTimeslotsAllowed";
    static readonly SET_PACKAGE_CART = "BookingAction.SetPackageCart";

    static readonly SET_FILTER = "BookingAction.SetFilter";
    static readonly SET_FILTER_ACTIVITIES = "BookingAction.SetFilterActivities";
    static readonly SET_PACKAGE = "BookingAction.SetPackage";
    static readonly PUSH_RESERVATION_NOTE = "BookingAction.PushReservationNote";
    static readonly RESET_RESERVATION_NOTE = "BookingAction.ResetReservationNote";

    static readonly SET_UPSELL = "BookingAction.SetUpsell";
    static readonly PUSH_UPSELL = "BookingAction.PushUpsell";
    static readonly REMOVE_UPSELL = "BookingAction.RemoveUpsell";
    static readonly CLEAR_UPSELLS = "BookingAction.ClearUpsells";
    static readonly SET_CUSTOMER = "BookingAction.SetCustomer";
    static readonly SET_COOKIES = "BookingAction.SetCookies";
    static readonly SET_PACKAGES_CART_PAYMENT = "BookingAction.SetPackagesCartPayment";

    static readonly SET_CURRENT_NAVIGATION_STEP = "BookingAction.SetCurrentNavigationStep";
    static readonly CLEAR_BOOKING_PROCESS_STATE = "BookingAction.ClearBookingProcessState";

    static fetchActivities = (): ThunkAction<Promise<BookingActivitiesResponse>> => {
        return dispatch => {
            return ajax
                .get("/booking/activities", {
                    params: {
                        hall_id: DEFAULT_HALL_ID,
                    },
                })
                .then((r: BookingActivitiesResponse) => {
                    dispatch<BookingReducerSetActivitiesAction>(BookingAction.setActivities(r.activities));
                    return r;
                });
        };
    };

    static fetchPackages = (data: BookingPackagesInput): ThunkAction<Promise<BookingPackagesResponse>> => {
        return dispatch => {
            return ajax
                .get("/booking/packages", {
                    params: {
                        hall_id: DEFAULT_HALL_ID,
                        ...data,
                        respectDaysLimit: false,
                    },
                })
                .then((r: BookingPackagesResponse) => {
                    dispatch<BookingReducerSetPackagesAction>(BookingAction.setPackages(r.packages));
                    dispatch<BookingReducerSetTimeslotsAllowedAction>(BookingAction.setTimeslotsAllowed(r.timeslotsAllowed));
                    return r;
                });
        };
    };

    static fetchPackagesByLocationParams = (location, filter) => {
        return dispatch => {
            const queryParams = new URLSearchParams(location.search);
            const filterParams: any = Object.fromEntries(queryParams.entries());

            if (!filterParams) {
                return;
            }

            filterParams.activity_ids = filterParams.activity_ids?.split(",") || filter.activity_ids || [];
            filterParams.adults = +filterParams.adults || filter.adults || 0;
            filterParams.children = +filterParams.children || filter.children || 0;
            filterParams.date = filterParams.date || filter.date || Dates.ymd(new Date());

            dispatch(BookingAction.fetchPackages(filterParams));
        };
    };

    static fetchPackagesTimeSlots = (data: PackagesTimeSlots): ThunkAction<Promise<BookingPackagesTimeslotsResponse>> => {
        return dispatch => {
            return ajax
                .get("/booking/timeslots", {
                    params: {
                        ...data,
                    },
                })
                .then((r: {bookingTimeslots: BookingTimeslot[]}) => {
                    //console.log(r);
                    dispatch<BookingReducerSetTimeslotsAction>(BookingAction.setTimeslots(r.bookingTimeslots));
                    return r;
                });
        };
    };

    // static fetchBookPackage = (data: BookPackageParams): ThunkAction<Promise<BookingPackagesTimeslotsResponse>> => {
    //     return dispatch => {
    //         return ajax.post("/booking/book/package", data).then((r: {packageCart: PackagesCart}) => {
    //             dispatch(BookingAction.setPackageCart(r.packageCart));
    //             return r;
    //         });
    //     };
    // };

    static fetchBookPackageWithUpsell = (data: BookPackageUpsellParams, packagesCart: PackagesCart): (dispatch) => Promise<{packageCart: PackagesCart} | BookingPackagesTimeslotsResponse> => {
        return dispatch => {
            return ajax.post("/booking/cart/" + packagesCart.id + "/" + packagesCart.token + "/book/package", data).then((r: {packageCart: PackagesCart}) => {
                dispatch(BookingAction.setPackageCart(r.packageCart));
                return r;
            });
        };
    };

    static fetchBookPackage = (data: BookTimeslotParams, packagesCart: PackagesCart): ThunkAction<Promise<BookingPackagesTimeslotsResponse>> => {
        return dispatch => {
            return ajax
                .post("/booking/cart/" + packagesCart.id + "/" + packagesCart.token + "/book/package", data)
                .then((r: {packageCart: PackagesCart; timeslot: Timeslot}) => {
                    dispatch<BookingReducerSetPackageCartAction>(BookingAction.setPackageCart(r.packageCart));
                    return r;
                });
        };
    };

    static initCart = (): ThunkAction<Promise<BookingInitCartResponse>> => {
        return dispatch => {
            return ajax.post("/booking/cart/create", {hall_id: DEFAULT_HALL_ID}).then(r => {
                dispatch<BookingReducerSetPackageCartAction>(BookingAction.setPackageCart(r.packageCart));
                dispatch<Action<string, Payment | null>>(BookingAction.setPackagesCartPayment(null));
                // dispatch(setSession(r.session));
                return r;
            });
        };
    };

    static sendReservationNote = (packagesCart: PackagesCart, note: string): ThunkAction<Promise<BookingInitCartResponse>> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;
            return ajax
                .post(
                    "/booking/cart/" + packagesCart.id + "/" + packagesCart.token + "/note",
                    {note: note},
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${session.access_token}`,
                        },
                    }
                )
                .then(r => {
                    return r;
                });
        };
    };

    static resetReservationNote = (): BookingReducerResetReservationNoteAction => {
        return createAction(BookingAction.RESET_RESERVATION_NOTE, null);
    };

    static pushReservationNote = (data: ReservationNote): BookingReducerPushReservationNoteAction => {
        return createAction(BookingAction.PUSH_RESERVATION_NOTE, data);
    };

    static fetchPackageCart = (packagesCart: PackagesCart): ThunkAction<Promise<BookingPackagesTimeslotsResponse>> => {
        return dispatch => {
            return ajax
                .get("/booking/cart/" + packagesCart.id + "/" + packagesCart.token, {params: {hall_id: DEFAULT_HALL_ID}})
                .then((r: {packageCart: PackagesCart}) => {
                    dispatch<BookingReducerSetPackageCartAction>(BookingAction.setPackageCart(r.packageCart));
                    return r;
                });
        };
    };

    static fetchSetCustomer = (
        data: CompanyInputs,
        packagesCart: PackagesCart | null
    ): ((dispatch) => Promise<{customer: Company} | BookingPackagesTimeslotsResponse>) => {
        return dispatch => {
            // @ts-ignore
            return ajax.post("/booking/cart/" + packagesCart.id + "/" + packagesCart.token + "/set-customer", data).then((r: {customer: Company}) => {
                dispatch(BookingAction.setCustomer(r.customer));
                return r;
            });
        };
    };

    static fetchPayCart = (packagesCart: PackagesCart): ThunkAction<Promise<any>> => {
        return (dispatch, getState) => {
            const session = getState().sessionState.session;
            return ajax
                .post("/booking/cart/" + packagesCart.id + "/" + packagesCart.token + "/pay", {
                    return_url: location.protocol + "//" + location.host + "/booking/paid",
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then((r: {redirect: string}) => {
                    window.location.href = r.redirect;
                    return r;
                });
        };
    };


    static fetchPaidGiftCardCart = (payment_id: number, paymentReference: string): ThunkAction<Promise<any>> => {
        return (dispatch, getState) => {
            const session = getState().sessionState.session;

            return ajax
              .get("/booking/cart/paid/giftcard/" + payment_id + "/" + paymentReference, {
                  withCredentials: true,
                  headers: {
                      Authorization: `Bearer ${session.access_token}`,
                  },
              })
              .then((r: {payment: Payment; assert: any}) => {
                  dispatch<Action<string, Payment | null>>(BookingAction.setPackagesCartPayment(r.payment));
                  return r;
              });
        };
    };

    static fetchPaidCart = (payment_id: number, paymentReference: string): ThunkAction<Promise<any>> => {
        return (dispatch, getState) => {
            const session = getState().sessionState.session;

            return ajax
                .get("/booking/cart/paid/" + payment_id + "/" + paymentReference, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session && session.access_token}`,
                    },
                })
                .then((r: {payment: Payment; assert: any}) => {
                    dispatch<Action<string, Payment | null>>(BookingAction.setPackagesCartPayment(r.payment));
                    return r;
                });
        };
    };

    static setActivities = (activities: Activity[]): BookingReducerSetActivitiesAction => {
        return createAction(BookingAction.SET_ACTIVITIES, activities);
    };

    static setPackages = (packages: Package[]): BookingReducerSetPackagesAction => {
        return createAction(BookingAction.SET_PACKAGES, packages);
    };

    static setTimeslots = (timeslots: BookingTimeslot[]): BookingReducerSetTimeslotsAction => {
        return createAction(BookingAction.SET_TIMESLOTS, timeslots);
    };
    static setTimeslotsAllowed = (timeslotsAllowed: boolean): BookingReducerSetTimeslotsAllowedAction => {
        return createAction(BookingAction.SET_TIMESLOTS_ALLOWED, timeslotsAllowed);
    };

    static setPackageCart = (packageCart: PackagesCart): BookingReducerSetPackageCartAction => {
        return createAction(BookingAction.SET_PACKAGE_CART, packageCart);
    };

    static setFilter = (data: BookingFilter): BookingReducerSetFilterAction => {
        return createAction(BookingAction.SET_FILTER, data);
    };

    static setPackage = (data: Package | null): BookingReducerSetPackageAction => {
        return createAction(BookingAction.SET_PACKAGE, data);
    };

    static pushUpsell = (data: PackageUpsell): BookingReducerPushUpsellAction => {
        return createAction(BookingAction.PUSH_UPSELL, data);
    };

    static setUpsell = (data: PackageUpsell): BookingReducerSetUpsellAction => {
        return createAction(BookingAction.SET_UPSELL, data);
    };

    static removeUpsell = (data: PackageUpsell) => {
        return createAction(BookingAction.REMOVE_UPSELL, data);
    };

    static setCustomer = (data: Company): BookingReducerSetCustomerAction => {
        return createAction(BookingAction.SET_CUSTOMER, data);
    };

    static setCookies = cookies => {
        return createAction(BookingAction.SET_COOKIES, cookies);
    };

    static setPackagesCartPayment = (payment: Payment | null) => {
        return createAction(BookingAction.SET_PACKAGES_CART_PAYMENT, payment);
    };

    static setCurrentNavigationStep = navigationStep => {
        return createAction(BookingAction.SET_CURRENT_NAVIGATION_STEP, navigationStep);
    };

    static clearBookingProcess = (payload: null = null) => {
        return createAction(BookingAction.CLEAR_BOOKING_PROCESS_STATE, payload);
    };

    static clearUpsells = (payload: [] = []) => {
        return createAction(BookingAction.CLEAR_UPSELLS, payload);
    };

    static postInquiryMessage = (data: {
        name: string;
        last_name: string;
        email: string;
        phone: string;
        subject: string;
        message: string;
        hall_id: string;
    }): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            return ajax.post("/app/post-inquiry", data).then(r => {
                return r;
            });
        };
    };

    static fetchCreateInquiry = (packagesCart: PackagesCart): ThunkAction<Promise<any>> => {
        return dispatch => {
            return ajax.post("/booking/cart/" + packagesCart.id + "/" + packagesCart.token + "/inquiry", {}).then(r => {
                return r;
            });
        };
    };

    static confirmBookingByCustomer = (data): ThunkAction<Promise<any>> => {
        return dispatch => {
            return ajax
                .post("/booking/" + data.package_cart_guid + "/" + data.package_cart_token + "/confirm-by-customer", {
                    email: btoa(data.email),
                })
                .then(r => {
                    return r;
                });
        };
    };

    static getReservationStatus = (package_cart_guid: string, package_cart_token: string): ThunkAction<Promise<any>> => {
        return dispatch => {
            return ajax.get("/booking/cart/" + package_cart_guid + "/" + package_cart_token + "/status").then(r => {
                return r;
            });
        };
    };
}

export default BookingAction;
